import React, { useState } from 'react';
import RuleDetail, { Rule } from '../../RuleDetail';
import Dialog from '@material-ui/core/Dialog';
import { DialogTitle } from '@material-ui/core';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import styled from 'styled-components';
import Loading from '../../Loading';
import { useUpdateRuleMutation, UpdateRuleInput } from '../../../graphql/types';
import { useErrorDialog } from '../../../hooks/error-dialog';
// @ts-ignore
import * as omitDeep from 'omit-deep-lodash';
import { sanitiseRule } from '../../utils/sanitisers';
import { validate } from '../../../services/rule-validator.service';
import RuleForm from '../../RuleDetail/RuleForm';
import Transition from '../Transition';
import { isLendiCodeConfigured } from '../../utils/validators';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const mapToUpdateRuleInput = (rule: Rule): UpdateRuleInput => {
  let sanitisedRule = sanitiseRule(rule);
  return {
    id: sanitisedRule.id,
    expectedVersion: sanitisedRule.version,
    name: sanitisedRule.name,
    category: sanitisedRule.category,
    active: sanitisedRule.active,
    allowBrokerMitigation: sanitisedRule.allowBrokerMitigation,
    allowCustomerMitigation: sanitisedRule.allowCustomerMitigation,
    includedInCoversheet: sanitisedRule.includedInCoversheet,
    singleCondition: sanitisedRule.singleCondition,
    appliesToNewLender: sanitisedRule.appliesToNewLender,
    criteria: sanitisedRule.criteria,
    description: sanitisedRule.description,
    mitigations: sanitisedRule.mitigations,
    defaultMitigation: sanitisedRule.defaultMitigation,
    variationOnly: sanitisedRule.variationOnly,
    lendiCodeRating: sanitisedRule.lendiCodeRating,
  } as UpdateRuleInput;
};

/**
 * EditRuleDialog component display current rule with its details
 * @param currentRule - current rule that will display by component
 * @param onCloseDialog - callback hook for closing dialog
 * @param readOnly - whether component should should operate in readOnly mode, default value to false
 * @constructor
 **/
interface EditRuleDialogProps {
  currentRule: Rule;
  onCloseDialog: (refresh: boolean) => void;
  readOnly?: boolean;
}

const EditRuleDialog: React.FC<EditRuleDialogProps> = ({
  currentRule,
  onCloseDialog,
  readOnly,
}) => {
  const [rule] = useState(
    omitDeep(
      {
        ...currentRule,
      },
      '__typename'
    )
  );
  const [open, setOpen] = useState(true);
  const [loading, setLoading] = useState(false);
  const errorDialog = useErrorDialog();
  const [updateRule] = useUpdateRuleMutation();

  const handleClose = (refresh: boolean) => {
    setLoading(false);
    setOpen(false);
    onCloseDialog(refresh);
  };

  const displayError = (error: string) => {
    setOpen(true);
    setLoading(false);
    errorDialog?.showErrorDialog(true);
    errorDialog?.setErrorDialogContent('' + error);
  };

  const showLoading = () => {
    setOpen(false);
    setLoading(true);
  };

  const saveRule = (rule: Rule) => {
    return updateRule({
      variables: {
        rule: mapToUpdateRuleInput(rule),
      },
    });
  };

  const save = (updatedRule: Rule) => {
    let ruleEdited = { ...rule, ...updatedRule } as Rule;

    if (!isLendiCodeConfigured(updatedRule) && ruleEdited.lendiCodeRating) {
      ruleEdited.lendiCodeRating.lendiCode = 'Off';
    }

    showLoading();
    return validate(ruleEdited.criteria)
      .then(() => saveRule(ruleEdited))
      .then(() => handleClose(true))
      .catch(error => displayError(error));
  };

  return (
    <Container>
      {loading && <Loading />}
      <Dialog
        open={open}
        TransitionComponent={Transition}
        fullWidth
        maxWidth="md">
        <DialogTitle>View/Edit Rule</DialogTitle>
        <RuleForm defaultValues={rule} onSubmit={save}>
          <DialogContent>
            <RuleDetail rule={rule} />
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={() => handleClose(false)}>
              Dismiss
            </Button>
            <Button color="secondary" type={'submit'} disabled={readOnly}>
              Save
            </Button>
          </DialogActions>
        </RuleForm>
      </Dialog>
    </Container>
  );
};

EditRuleDialog.defaultProps = {
  readOnly: false,
};

export default EditRuleDialog;
