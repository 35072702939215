import { Rule } from '../RuleDetail';

export const validateNonEmptyString = (value?: string): boolean => {
  if (value === '' || value === null || value === undefined) {
    return false;
  }
  return true;
};

export const isLendiCodeConfigured = (rule: Rule): boolean => {
  if (rule.lendiCodeRating && rule.lendiCodeRating.lendiCode === null) {
    return false;
  }
  return true;
};
