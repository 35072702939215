import React from 'react';
import { useAuth0 } from '../../hooks/auth0';

const SignIn: React.FC = () => {
  const auth0 = useAuth0();
  if (auth0?.isAuthenticated === false && auth0?.loading === false) {
    auth0?.auth0Client?.loginWithRedirect();
  }
  return <div />;
};

export default SignIn;
