import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { Controller } from 'react-hook-form';
import React from 'react';
import { DefaultRating } from './index';

interface LendiCodeAndRatingSelectProps {
  rating: string;
  fieldName: string;
  defaultRating?: string;
}

const LendiCodeAndRatingSelect: React.FC<LendiCodeAndRatingSelectProps> = ({
  rating,
  fieldName,
  defaultRating = DefaultRating,
}) => {
  return (
    <FormControl fullWidth>
      <InputLabel>Difficulty Rating</InputLabel>
      <Controller
        name={fieldName}
        defaultValue={rating || defaultRating}
        render={({ field }) => (
          <Select {...field}>
            <MenuItem key={`${fieldName}-high`} value={'High'}>
              High
            </MenuItem>
            <MenuItem key={`${fieldName}-medium`} value={'Medium'}>
              Medium
            </MenuItem>
            <MenuItem key={`${fieldName}-low`} value={'Low'}>
              Low
            </MenuItem>
            <MenuItem key={`${fieldName}-decline`} value={'Decline'}>
              Decline
            </MenuItem>
            <MenuItem key={`${fieldName}-na`} value={'NA'}>
              N/A
            </MenuItem>
            <MenuItem key={`${fieldName}-off`} value={DefaultRating}>
              Off
            </MenuItem>
          </Select>
        )}
      />
    </FormControl>
  );
};

export default LendiCodeAndRatingSelect;
