import axios from 'axios';

const RULES_VALIDATION_API_URL =
  process.env.REACT_APP_RULES_VALIDATION_API_URL || '';

interface ValidationResult {
  successful: boolean;
  errorMessage?: string;
}

export const validate = async (criteria?: string): Promise<void> => {
  if (criteria === undefined || criteria === null || criteria === '') {
    return Promise.reject('Criteria is undefined or empty!');
  }
  const response = await axios.post(RULES_VALIDATION_API_URL, { criteria });
  const result: ValidationResult = response.data?.data;
  if (!result.successful) {
    return Promise.reject(result.errorMessage);
  }
  return Promise.resolve();
};
