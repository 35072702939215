import React from 'react';
import { ErrorDialogProvider } from '../hooks/error-dialog';

const withErrorDialogProvider = <P extends object>(
  Component: React.ComponentType<P>
) => {
  return (props: any) => {
    return (
      <ErrorDialogProvider>
        <Component {...(props as P)} />
      </ErrorDialogProvider>
    );
  };
};

export { withErrorDialogProvider };
