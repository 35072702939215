import React from 'react';
import styled from 'styled-components';
import Skeleton from '@material-ui/lab/Skeleton';

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  margin-top: 4px;
  justify-content: center;
`;

const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 280px;
  width: 100%;
  margin: 64px 100px;
`;

const RowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
`;

const TableLoading: React.FC = () => {
  const rows = 6;
  const cols = 4;
  return (
    <Container>
      <TableContainer>
        {[...Array(rows)].map((_, i) => (
          <RowContainer key={i}>
            {[...Array(cols)].map((_, j) => (
              <Skeleton key={j} variant="rect" width="24.75%" height={32} />
            ))}
          </RowContainer>
        ))}
      </TableContainer>
    </Container>
  );
};

export default TableLoading;
