import { DocType } from '../../../hooks/doctypes';
import {
  Rule,
  Scalars,
  Maybe,
  MitigationRequirement,
} from '../../../graphql/types';

const csvRuleHeaders = [
  'Rule Name',
  'Rule Description',
  'Rule Criteria',
  'Category',
  'Active',
  'Broker Mitigable',
  'Customer Mitigable',
  'Included in Coversheet',
  'Single Condition',
  'Variation Only',
  'Lender for Mitigation',
  'Mitigation Description',
  'Override',
  'Hardstop',
  'Requires Comment',
  'Doc Types',
];

const csvLendiCodeHeaders = [
  'Rule Name',
  'Rule Description',
  'Rule Criteria',
  'Category',
  'Active',
  'Broker Mitigable',
  'Customer Mitigable',
  'Included in Coversheet',
  'Single Condition',
  'Variation Only',
  'Lendi Code',
  'Lender for Lendi Code',
  'Difficulty Rating',
];

/* Go through all mitigations and find the max number of doc types */
const findMaxDocTypes = (selectedRules: Rule[]) => {
  let maxDocTypes = 1;

  selectedRules.forEach(rule => {
    // default mitigation
    const defaultMitigation = rule.defaultMitigation;
    if (
      defaultMitigation !== null &&
      defaultMitigation.mitigationRequirement !== null
    ) {
      const numberOfDocTypes = defaultMitigation.mitigationRequirement!
        .requiredDocTypes.length;
      maxDocTypes =
        maxDocTypes < numberOfDocTypes ? numberOfDocTypes : maxDocTypes;
    }

    // other mitigations
    rule.mitigations.forEach(mitigation => {
      if (mitigation.mitigationRequirement !== null) {
        const numberOfDocTypes = mitigation.mitigationRequirement!
          .requiredDocTypes.length;
        maxDocTypes =
          maxDocTypes < numberOfDocTypes ? numberOfDocTypes : maxDocTypes;
      }
    });
  });

  return maxDocTypes;
};

/* Returns doc type names for given UUIDs, any comma (,) will be changed to semicolon (;) */
const getDocTypeNames = (uuids: Array<string>, docTypes: DocType[]) => {
  const getDocTypeById = (id: string) =>
    docTypes && docTypes.find(docType => docType.id === id)!;

  return uuids
    .map(id => {
      const docType = getDocTypeById(id);
      return docType && docType.name.replaceAll(',', ';');
    })
    .filter(v => v);
};

const booleanToString = (value: boolean | null | undefined) => {
  return value ? '1,' : '0,';
};

/* Set lender name to Default if lender list is empty. */
const getMitigationDetails = (
  maxDocTypes: number,
  description: string,
  lenders: Array<Scalars['String']>,
  lenderDict: { [_: string]: string },
  docTypes: DocType[],
  mitigationRequirement?: Maybe<MitigationRequirement>
) => {
  let mitigationDetails = '';

  const lenderNames = lenders.map(uuid => lenderDict[uuid]);
  let lenderStr = lenderNames.length > 0 ? lenderNames.join(';') : 'Default';

  mitigationDetails += lenderStr + ',';
  mitigationDetails += description + ',';

  if (mitigationRequirement !== undefined && mitigationRequirement !== null) {
    mitigationDetails += booleanToString(mitigationRequirement.allowOverride);
    mitigationDetails += booleanToString(mitigationRequirement.hardstop);
    mitigationDetails += booleanToString(mitigationRequirement.commentRequired);

    const docTypeNames = getDocTypeNames(
      mitigationRequirement.requiredDocTypes,
      docTypes
    );
    let docTypesStr = docTypeNames.join(',');

    const docTypesSize = docTypeNames.length;
    if (docTypesSize < maxDocTypes - 1) {
      // maxDocTypes = 4, docTypesSize = 2, pad 1 ,
      const repeatTimes = Math.max(0, maxDocTypes - docTypesSize - 1);
      docTypesStr += ','.repeat(repeatTimes);
    }
    mitigationDetails += docTypesStr;
  } else {
    // empty cells for allowOverride, hardstop, commentRequired and doc types
    const docTypeRepeatTimes = Math.max(0, maxDocTypes - 1);
    mitigationDetails += ',,,' + ','.repeat(docTypeRepeatTimes);
  }

  return mitigationDetails;
};

/* Replaces comma with semicolon and removes new line characters. */
const formatMultilines = (text: string | null | undefined) => {
  if (text === null || text === undefined) {
    return '';
  }

  const noCommas = text.replaceAll(',', ';');
  return noCommas.replaceAll(/\r?\n|\r/g, ' ');
};

const getBasicDetails = (rule: Rule) => {
  let ruleDetails = rule.name.replaceAll(',', ';') + ',';
  ruleDetails += formatMultilines(rule.description) + ',';
  ruleDetails += formatMultilines(rule.criteria) + ',';
  ruleDetails += rule.category.toString() + ',';
  ruleDetails += booleanToString(rule.active);
  ruleDetails += booleanToString(rule.allowBrokerMitigation);
  ruleDetails += booleanToString(rule.allowCustomerMitigation);
  ruleDetails += booleanToString(rule.includedInCoversheet);
  ruleDetails += booleanToString(rule.singleCondition);
  ruleDetails += booleanToString(rule.variationOnly);
  return ruleDetails;
};

/*
 * Transforms the given rules to csv formatted string.
 * Each mitigation is on each own line. Common rule details
 * such as rule name and description are repeated for each mitigation.
 */
export const toRuleCsv = (
  selectedRules: Rule[],
  lenderDict: { [_: string]: string },
  docTypes: DocType[]
) => {
  const maxDocTypes = findMaxDocTypes(selectedRules);
  let csvContent =
    csvRuleHeaders.join(',') + ','.repeat(maxDocTypes - 1) + '\n';

  selectedRules.forEach(rule => {
    let ruleDetails = getBasicDetails(rule);

    // default mitigation
    const defaultMitigation = rule.defaultMitigation;
    if (defaultMitigation !== null) {
      const description = formatMultilines(defaultMitigation.description);
      const mitigationDetails = getMitigationDetails(
        maxDocTypes,
        description,
        [],
        lenderDict,
        docTypes,
        defaultMitigation.mitigationRequirement
      );
      csvContent += ruleDetails + mitigationDetails + '\n';
    }

    // other mitigations
    rule.mitigations.forEach(mitigation => {
      const requirement = mitigation.mitigationRequirement;

      if (requirement !== null) {
        const mitigationDescription = mitigation.description;
        const description = formatMultilines(mitigationDescription);
        const mitigationDetails = getMitigationDetails(
          maxDocTypes,
          description,
          mitigation.lenders,
          lenderDict,
          docTypes,
          mitigation.mitigationRequirement
        );
        csvContent += ruleDetails + mitigationDetails + '\n';
      }
    });
  });

  return csvContent;
};

export const toLendiCodeCsv = (
  selectedRules: Rule[],
  lenderDict: { [_: string]: string }
) => {
  let csvContent = csvLendiCodeHeaders.join(',') + '\n';

  selectedRules.forEach(rule => {
    let ruleDetails = getBasicDetails(rule);
    if (rule.lendiCodeRating != null) {
      const lendiCodeRating = rule.lendiCodeRating;
      const lendiCodeName = lendiCodeRating.lendiCode;
      // default lendi code rating
      const lendiCodeDefaultDetails = `${lendiCodeName},Default,${lendiCodeRating.defaultRating}`;
      csvContent += ruleDetails + lendiCodeDefaultDetails + '\n';

      // other lendi code rating
      rule.lendiCodeRating.ratings.forEach(rating => {
        rating.lenders.forEach(lender => {
          const lendiCodeDetails = `${lendiCodeName},${lenderDict[lender]},${rating.rating}`;
          csvContent += ruleDetails + lendiCodeDetails + '\n';
        });
      });
    } else {
      csvContent += ruleDetails + 'Off,Default,Off\n';
    }
  });

  return csvContent;
};
