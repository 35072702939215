import React from 'react';
import {
  createStyles,
  makeStyles,
  lighten,
  Theme,
} from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import { Button } from '@material-ui/core';
import SaveAltIcon from '@material-ui/core/SvgIcon/SvgIcon';
import InputIcon from '@material-ui/icons/Input';
import styled from 'styled-components';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolBar: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    highlight: {
      color: theme.palette.text.primary,
      backgroundColor: lighten(theme.palette.info.dark, 0.5),
    },
    title: {
      flex: '1 1 100%',
    },
    importExportButton: {
      width: '20em',
      marginLeft: '10px',
    },
  })
);

const ExportButton = styled(Button)`
  margin-left: 10px;
  width: 20em;
`;

interface LendiCodeToolBarProps {
  selectedRows: any[];
  handleExportToJson: () => void;
  handleExportToCsv: () => void;
  handleImportClicked: () => void;
  tableName: string;
  shouldDisplayExportButtons: boolean;
  shouldDisplayImportButtons: boolean;
  shouldHighlightToolBar: boolean;
  shouldDisplayNumberOfItemsSelected: boolean;
}

const ImportExportToolBar: React.FC<LendiCodeToolBarProps> = ({
  selectedRows,
  handleExportToJson,
  handleExportToCsv,
  handleImportClicked,
  tableName,
  shouldDisplayExportButtons,
  shouldDisplayImportButtons,
  shouldHighlightToolBar,
  shouldDisplayNumberOfItemsSelected,
}) => {
  const classes = useStyles();
  return (
    <Toolbar
      className={clsx(classes.toolBar, {
        [classes.highlight]: shouldHighlightToolBar,
      })}>
      {shouldDisplayExportButtons && (
        <>
          {shouldDisplayNumberOfItemsSelected && (
            <Typography
              className={classes.title}
              color={'inherit'}
              variant="subtitle1"
              component="div">
              {selectedRows.length} selected
            </Typography>
          )}
          <ExportButton
            color="primary"
            onClick={() => handleExportToCsv()}
            disableTouchRipple={true}
            size={'small'}
            fullWidth={false}
            endIcon={<SaveAltIcon />}
            variant={'contained'}
            disabled={selectedRows.length === 0}>
            Export to CSV
          </ExportButton>
          <ExportButton
            color="primary"
            onClick={() => handleExportToJson()}
            disableTouchRipple={true}
            size={'small'}
            fullWidth={false}
            endIcon={<SaveAltIcon />}
            variant={'contained'}
            disabled={selectedRows.length === 0}>
            Export to JSON
          </ExportButton>
        </>
      )}
      {shouldDisplayImportButtons && (
        <>
          {tableName && (
            <Typography
              className={classes.title}
              variant="h6"
              id="tableTitle"
              component="div">
              {tableName}
            </Typography>
          )}
          <Button
            className={classes.importExportButton}
            color="default"
            onClick={() => handleImportClicked()}
            disableTouchRipple={true}
            size={'small'}
            fullWidth={false}
            endIcon={<InputIcon />}
            variant={'contained'}>
            Import from JSON
          </Button>
        </>
      )}
    </Toolbar>
  );
};

export default ImportExportToolBar;
