/* eslint-disable */
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export enum Action {
  ActionRequired = 'Action_Required',
  NoActionRequired = 'No_Action_Required'
}

export enum Category {
  Acknowledgements = 'Acknowledgements',
  Applicants = 'Applicants',
  Assets = 'Assets',
  Compliance = 'Compliance',
  Conduct = 'Conduct',
  Expenses = 'Expenses',
  IdVerification = 'IdVerification',
  Income = 'Income',
  Liabilities = 'Liabilities',
  LoanStructure = 'LoanStructure',
  LoanSummary = 'LoanSummary',
  Property = 'Property',
  Servicing = 'Servicing',
  Settlement = 'Settlement',
  Submission = 'Submission',
  Unconditional = 'Unconditional'
}

export type DefaultMitigation = {
  __typename?: 'DefaultMitigation';
  action: Action;
  description?: Maybe<Scalars['String']>;
  mitigationRequirement?: Maybe<MitigationRequirement>;
};

export type DefaultMitigationInput = {
  action: Action;
  description?: InputMaybe<Scalars['String']>;
  mitigationRequirement?: InputMaybe<MitigationRequirementInput>;
};

export enum DifficultyRating {
  Decline = 'Decline',
  High = 'High',
  Low = 'Low',
  Medium = 'Medium',
  Na = 'NA',
  Off = 'Off'
}

export type IacLendersConfig = {
  __typename?: 'IacLendersConfig';
  lenders: Array<Scalars['String']>;
};

export type LendiCode = {
  __typename?: 'LendiCode';
  description?: Maybe<Scalars['String']>;
  lendiCode: Scalars['String'];
};

export type LendiCodeRating = {
  __typename?: 'LendiCodeRating';
  defaultRating: DifficultyRating;
  lendiCode: Scalars['String'];
  ratings: Array<LendiRating>;
};

export type LendiCodeRatingInput = {
  defaultRating: DifficultyRating;
  lendiCode: Scalars['String'];
  ratings: Array<LendiRatingInput>;
};

export type LendiRating = {
  __typename?: 'LendiRating';
  lenders: Array<Scalars['String']>;
  rating: DifficultyRating;
};

export type LendiRatingInput = {
  lenders: Array<Scalars['String']>;
  rating: DifficultyRating;
};

export type Mitigation = {
  __typename?: 'Mitigation';
  description?: Maybe<Scalars['String']>;
  lenders: Array<Scalars['String']>;
  mitigationRequirement?: Maybe<MitigationRequirement>;
};

export type MitigationInput = {
  description?: InputMaybe<Scalars['String']>;
  lenders: Array<Scalars['String']>;
  mitigationRequirement?: InputMaybe<MitigationRequirementInput>;
};

export type MitigationRequirement = {
  __typename?: 'MitigationRequirement';
  allowOverride: Scalars['Boolean'];
  commentRequired: Scalars['Boolean'];
  hardstop: Scalars['Boolean'];
  requiredDocTypes: Array<Scalars['String']>;
};

export type MitigationRequirementInput = {
  allowOverride: Scalars['Boolean'];
  commentRequired: Scalars['Boolean'];
  hardstop: Scalars['Boolean'];
  requiredDocTypes: Array<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createIacLendersConfig?: Maybe<IacLendersConfig>;
  createLendiCode: LendiCode;
  createRule: Rule;
  removeRule?: Maybe<Rule>;
  updateLendiCode?: Maybe<LendiCode>;
  updateRule: Rule;
};


export type MutationCreateIacLendersConfigArgs = {
  lenderIds: Array<Scalars['String']>;
};


export type MutationCreateLendiCodeArgs = {
  lendiCode: NewLendiCodeInput;
};


export type MutationCreateRuleArgs = {
  rule: NewRuleInput;
};


export type MutationRemoveRuleArgs = {
  id: Scalars['ID'];
};


export type MutationUpdateLendiCodeArgs = {
  lendiCode?: InputMaybe<UpdateLendiCodeInput>;
};


export type MutationUpdateRuleArgs = {
  rule: UpdateRuleInput;
};

export type NewLendiCodeInput = {
  description?: InputMaybe<Scalars['String']>;
  lendiCode: Scalars['String'];
};

export type NewRuleInput = {
  active: Scalars['Boolean'];
  allowBrokerMitigation: Scalars['Boolean'];
  allowCustomerMitigation: Scalars['Boolean'];
  appliesToNewLender: Scalars['Boolean'];
  category: Category;
  criteria: Scalars['String'];
  defaultMitigation: DefaultMitigationInput;
  description: Scalars['String'];
  id?: InputMaybe<Scalars['ID']>;
  includedInCoversheet: Scalars['Boolean'];
  lendiCodeRating?: InputMaybe<LendiCodeRatingInput>;
  mitigations: Array<MitigationInput>;
  name: Scalars['String'];
  singleCondition: Scalars['Boolean'];
  variationOnly: Scalars['Boolean'];
};

export type PaginatedLendiCodes = {
  __typename?: 'PaginatedLendiCodes';
  lendiCodes: Array<LendiCode>;
  nextToken?: Maybe<Scalars['String']>;
};

export type PaginatedRules = {
  __typename?: 'PaginatedRules';
  nextToken?: Maybe<Scalars['String']>;
  rules: Array<Rule>;
};

export type Query = {
  __typename?: 'Query';
  getIacLendersConfig?: Maybe<IacLendersConfig>;
  getRule?: Maybe<Rule>;
  listLendiCodes: PaginatedLendiCodes;
  listRules: PaginatedRules;
};


export type QueryGetRuleArgs = {
  id: Scalars['ID'];
};


export type QueryListLendiCodesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
};


export type QueryListRulesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
};

export type Rule = {
  __typename?: 'Rule';
  active: Scalars['Boolean'];
  allowBrokerMitigation: Scalars['Boolean'];
  allowCustomerMitigation: Scalars['Boolean'];
  appliesToNewLender: Scalars['Boolean'];
  category: Category;
  createdAt: Scalars['String'];
  criteria: Scalars['String'];
  defaultMitigation: DefaultMitigation;
  description: Scalars['String'];
  id: Scalars['ID'];
  includedInCoversheet: Scalars['Boolean'];
  lendiCodeRating?: Maybe<LendiCodeRating>;
  mitigations: Array<Mitigation>;
  modifiedBy: Scalars['String'];
  name: Scalars['String'];
  singleCondition: Scalars['Boolean'];
  updatedAt: Scalars['String'];
  variationOnly?: Maybe<Scalars['Boolean']>;
  version: Scalars['Int'];
};

export type UpdateLendiCodeInput = {
  description: Scalars['String'];
  lendiCode: Scalars['String'];
};

export type UpdateRuleInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  allowBrokerMitigation?: InputMaybe<Scalars['Boolean']>;
  allowCustomerMitigation?: InputMaybe<Scalars['Boolean']>;
  appliesToNewLender?: InputMaybe<Scalars['Boolean']>;
  category?: InputMaybe<Category>;
  criteria?: InputMaybe<Scalars['String']>;
  defaultMitigation?: InputMaybe<DefaultMitigationInput>;
  description?: InputMaybe<Scalars['String']>;
  expectedVersion: Scalars['Int'];
  id: Scalars['ID'];
  includedInCoversheet?: InputMaybe<Scalars['Boolean']>;
  lendiCodeRating?: InputMaybe<LendiCodeRatingInput>;
  mitigations?: InputMaybe<Array<MitigationInput>>;
  name?: InputMaybe<Scalars['String']>;
  singleCondition?: InputMaybe<Scalars['Boolean']>;
  variationOnly?: InputMaybe<Scalars['Boolean']>;
};

export type RuleFragmentFragment = { __typename?: 'Rule', id: string, version: number, modifiedBy: string, updatedAt: string, createdAt: string, name: string, description: string, criteria: string, active: boolean, allowBrokerMitigation: boolean, allowCustomerMitigation: boolean, includedInCoversheet: boolean, singleCondition: boolean, appliesToNewLender: boolean, category: Category, variationOnly?: boolean | null, mitigations: Array<{ __typename?: 'Mitigation', description?: string | null, lenders: Array<string>, mitigationRequirement?: { __typename?: 'MitigationRequirement', allowOverride: boolean, hardstop: boolean, commentRequired: boolean, requiredDocTypes: Array<string> } | null }>, defaultMitigation: { __typename?: 'DefaultMitigation', action: Action, description?: string | null, mitigationRequirement?: { __typename?: 'MitigationRequirement', allowOverride: boolean, hardstop: boolean, commentRequired: boolean, requiredDocTypes: Array<string> } | null }, lendiCodeRating?: { __typename?: 'LendiCodeRating', lendiCode: string, defaultRating: DifficultyRating, ratings: Array<{ __typename?: 'LendiRating', lenders: Array<string>, rating: DifficultyRating }> } | null };

export type LendiCodeFragmentFragment = { __typename?: 'LendiCode', lendiCode: string, description?: string | null };

export type UpdateRuleMutationVariables = Exact<{
  rule: UpdateRuleInput;
}>;


export type UpdateRuleMutation = { __typename?: 'Mutation', updateRule: { __typename?: 'Rule', id: string, version: number, modifiedBy: string, updatedAt: string, createdAt: string, name: string, description: string, criteria: string, active: boolean, allowBrokerMitigation: boolean, allowCustomerMitigation: boolean, includedInCoversheet: boolean, singleCondition: boolean, appliesToNewLender: boolean, category: Category, variationOnly?: boolean | null, mitigations: Array<{ __typename?: 'Mitigation', description?: string | null, lenders: Array<string>, mitigationRequirement?: { __typename?: 'MitigationRequirement', allowOverride: boolean, hardstop: boolean, commentRequired: boolean, requiredDocTypes: Array<string> } | null }>, defaultMitigation: { __typename?: 'DefaultMitigation', action: Action, description?: string | null, mitigationRequirement?: { __typename?: 'MitigationRequirement', allowOverride: boolean, hardstop: boolean, commentRequired: boolean, requiredDocTypes: Array<string> } | null }, lendiCodeRating?: { __typename?: 'LendiCodeRating', lendiCode: string, defaultRating: DifficultyRating, ratings: Array<{ __typename?: 'LendiRating', lenders: Array<string>, rating: DifficultyRating }> } | null } };

export type NewRuleMutationVariables = Exact<{
  rule: NewRuleInput;
}>;


export type NewRuleMutation = { __typename?: 'Mutation', createRule: { __typename?: 'Rule', id: string, version: number, modifiedBy: string, updatedAt: string, createdAt: string, name: string, description: string, criteria: string, active: boolean, allowBrokerMitigation: boolean, allowCustomerMitigation: boolean, includedInCoversheet: boolean, singleCondition: boolean, appliesToNewLender: boolean, category: Category, variationOnly?: boolean | null, mitigations: Array<{ __typename?: 'Mitigation', description?: string | null, lenders: Array<string>, mitigationRequirement?: { __typename?: 'MitigationRequirement', allowOverride: boolean, hardstop: boolean, commentRequired: boolean, requiredDocTypes: Array<string> } | null }>, defaultMitigation: { __typename?: 'DefaultMitigation', action: Action, description?: string | null, mitigationRequirement?: { __typename?: 'MitigationRequirement', allowOverride: boolean, hardstop: boolean, commentRequired: boolean, requiredDocTypes: Array<string> } | null }, lendiCodeRating?: { __typename?: 'LendiCodeRating', lendiCode: string, defaultRating: DifficultyRating, ratings: Array<{ __typename?: 'LendiRating', lenders: Array<string>, rating: DifficultyRating }> } | null } };

export type CreateIacLendersConfigMutationVariables = Exact<{
  lenderIds: Array<Scalars['String']> | Scalars['String'];
}>;


export type CreateIacLendersConfigMutation = { __typename?: 'Mutation', createIacLendersConfig?: { __typename?: 'IacLendersConfig', lenders: Array<string> } | null };

export type NewLendiCodeMutationVariables = Exact<{
  lendiCode: NewLendiCodeInput;
}>;


export type NewLendiCodeMutation = { __typename?: 'Mutation', createLendiCode: { __typename?: 'LendiCode', lendiCode: string, description?: string | null } };

export type UpdateLendiCodeMutationVariables = Exact<{
  lendiCode: UpdateLendiCodeInput;
}>;


export type UpdateLendiCodeMutation = { __typename?: 'Mutation', updateLendiCode?: { __typename?: 'LendiCode', lendiCode: string, description?: string | null } | null };

export type RulesQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
}>;


export type RulesQuery = { __typename?: 'Query', listRules: { __typename?: 'PaginatedRules', nextToken?: string | null, rules: Array<{ __typename?: 'Rule', id: string, version: number, modifiedBy: string, updatedAt: string, createdAt: string, name: string, description: string, criteria: string, active: boolean, allowBrokerMitigation: boolean, allowCustomerMitigation: boolean, includedInCoversheet: boolean, singleCondition: boolean, appliesToNewLender: boolean, category: Category, variationOnly?: boolean | null, mitigations: Array<{ __typename?: 'Mitigation', description?: string | null, lenders: Array<string>, mitigationRequirement?: { __typename?: 'MitigationRequirement', allowOverride: boolean, hardstop: boolean, commentRequired: boolean, requiredDocTypes: Array<string> } | null }>, defaultMitigation: { __typename?: 'DefaultMitigation', action: Action, description?: string | null, mitigationRequirement?: { __typename?: 'MitigationRequirement', allowOverride: boolean, hardstop: boolean, commentRequired: boolean, requiredDocTypes: Array<string> } | null }, lendiCodeRating?: { __typename?: 'LendiCodeRating', lendiCode: string, defaultRating: DifficultyRating, ratings: Array<{ __typename?: 'LendiRating', lenders: Array<string>, rating: DifficultyRating }> } | null }> } };

export type GetIacLendersConfigQueryVariables = Exact<{ [key: string]: never; }>;


export type GetIacLendersConfigQuery = { __typename?: 'Query', getIacLendersConfig?: { __typename?: 'IacLendersConfig', lenders: Array<string> } | null };

export type ListLendicodesQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
}>;


export type ListLendicodesQuery = { __typename?: 'Query', listLendiCodes: { __typename?: 'PaginatedLendiCodes', nextToken?: string | null, lendiCodes: Array<{ __typename?: 'LendiCode', lendiCode: string, description?: string | null }> } };

export const RuleFragmentFragmentDoc = gql`
    fragment RuleFragment on Rule {
  id
  version
  modifiedBy
  updatedAt
  createdAt
  name
  description
  criteria
  mitigations {
    description
    lenders
    mitigationRequirement {
      allowOverride
      hardstop
      commentRequired
      requiredDocTypes
    }
  }
  defaultMitigation {
    action
    description
    mitigationRequirement {
      allowOverride
      hardstop
      commentRequired
      requiredDocTypes
    }
  }
  active
  allowBrokerMitigation
  allowCustomerMitigation
  includedInCoversheet
  singleCondition
  appliesToNewLender
  category
  variationOnly
  lendiCodeRating {
    lendiCode
    ratings {
      lenders
      rating
    }
    defaultRating
  }
}
    `;
export const LendiCodeFragmentFragmentDoc = gql`
    fragment LendiCodeFragment on LendiCode {
  lendiCode
  description
}
    `;
export const UpdateRuleDocument = gql`
    mutation updateRule($rule: UpdateRuleInput!) {
  updateRule(rule: $rule) {
    ...RuleFragment
  }
}
    ${RuleFragmentFragmentDoc}`;
export type UpdateRuleMutationFn = Apollo.MutationFunction<UpdateRuleMutation, UpdateRuleMutationVariables>;

/**
 * __useUpdateRuleMutation__
 *
 * To run a mutation, you first call `useUpdateRuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRuleMutation, { data, loading, error }] = useUpdateRuleMutation({
 *   variables: {
 *      rule: // value for 'rule'
 *   },
 * });
 */
export function useUpdateRuleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRuleMutation, UpdateRuleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRuleMutation, UpdateRuleMutationVariables>(UpdateRuleDocument, options);
      }
export type UpdateRuleMutationHookResult = ReturnType<typeof useUpdateRuleMutation>;
export type UpdateRuleMutationResult = Apollo.MutationResult<UpdateRuleMutation>;
export type UpdateRuleMutationOptions = Apollo.BaseMutationOptions<UpdateRuleMutation, UpdateRuleMutationVariables>;
export const NewRuleDocument = gql`
    mutation newRule($rule: NewRuleInput!) {
  createRule(rule: $rule) {
    ...RuleFragment
  }
}
    ${RuleFragmentFragmentDoc}`;
export type NewRuleMutationFn = Apollo.MutationFunction<NewRuleMutation, NewRuleMutationVariables>;

/**
 * __useNewRuleMutation__
 *
 * To run a mutation, you first call `useNewRuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewRuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newRuleMutation, { data, loading, error }] = useNewRuleMutation({
 *   variables: {
 *      rule: // value for 'rule'
 *   },
 * });
 */
export function useNewRuleMutation(baseOptions?: Apollo.MutationHookOptions<NewRuleMutation, NewRuleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<NewRuleMutation, NewRuleMutationVariables>(NewRuleDocument, options);
      }
export type NewRuleMutationHookResult = ReturnType<typeof useNewRuleMutation>;
export type NewRuleMutationResult = Apollo.MutationResult<NewRuleMutation>;
export type NewRuleMutationOptions = Apollo.BaseMutationOptions<NewRuleMutation, NewRuleMutationVariables>;
export const CreateIacLendersConfigDocument = gql`
    mutation createIacLendersConfig($lenderIds: [String!]!) {
  createIacLendersConfig(lenderIds: $lenderIds) {
    lenders
  }
}
    `;
export type CreateIacLendersConfigMutationFn = Apollo.MutationFunction<CreateIacLendersConfigMutation, CreateIacLendersConfigMutationVariables>;

/**
 * __useCreateIacLendersConfigMutation__
 *
 * To run a mutation, you first call `useCreateIacLendersConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateIacLendersConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createIacLendersConfigMutation, { data, loading, error }] = useCreateIacLendersConfigMutation({
 *   variables: {
 *      lenderIds: // value for 'lenderIds'
 *   },
 * });
 */
export function useCreateIacLendersConfigMutation(baseOptions?: Apollo.MutationHookOptions<CreateIacLendersConfigMutation, CreateIacLendersConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateIacLendersConfigMutation, CreateIacLendersConfigMutationVariables>(CreateIacLendersConfigDocument, options);
      }
export type CreateIacLendersConfigMutationHookResult = ReturnType<typeof useCreateIacLendersConfigMutation>;
export type CreateIacLendersConfigMutationResult = Apollo.MutationResult<CreateIacLendersConfigMutation>;
export type CreateIacLendersConfigMutationOptions = Apollo.BaseMutationOptions<CreateIacLendersConfigMutation, CreateIacLendersConfigMutationVariables>;
export const NewLendiCodeDocument = gql`
    mutation newLendiCode($lendiCode: NewLendiCodeInput!) {
  createLendiCode(lendiCode: $lendiCode) {
    ...LendiCodeFragment
  }
}
    ${LendiCodeFragmentFragmentDoc}`;
export type NewLendiCodeMutationFn = Apollo.MutationFunction<NewLendiCodeMutation, NewLendiCodeMutationVariables>;

/**
 * __useNewLendiCodeMutation__
 *
 * To run a mutation, you first call `useNewLendiCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewLendiCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newLendiCodeMutation, { data, loading, error }] = useNewLendiCodeMutation({
 *   variables: {
 *      lendiCode: // value for 'lendiCode'
 *   },
 * });
 */
export function useNewLendiCodeMutation(baseOptions?: Apollo.MutationHookOptions<NewLendiCodeMutation, NewLendiCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<NewLendiCodeMutation, NewLendiCodeMutationVariables>(NewLendiCodeDocument, options);
      }
export type NewLendiCodeMutationHookResult = ReturnType<typeof useNewLendiCodeMutation>;
export type NewLendiCodeMutationResult = Apollo.MutationResult<NewLendiCodeMutation>;
export type NewLendiCodeMutationOptions = Apollo.BaseMutationOptions<NewLendiCodeMutation, NewLendiCodeMutationVariables>;
export const UpdateLendiCodeDocument = gql`
    mutation updateLendiCode($lendiCode: UpdateLendiCodeInput!) {
  updateLendiCode(lendiCode: $lendiCode) {
    ...LendiCodeFragment
  }
}
    ${LendiCodeFragmentFragmentDoc}`;
export type UpdateLendiCodeMutationFn = Apollo.MutationFunction<UpdateLendiCodeMutation, UpdateLendiCodeMutationVariables>;

/**
 * __useUpdateLendiCodeMutation__
 *
 * To run a mutation, you first call `useUpdateLendiCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLendiCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLendiCodeMutation, { data, loading, error }] = useUpdateLendiCodeMutation({
 *   variables: {
 *      lendiCode: // value for 'lendiCode'
 *   },
 * });
 */
export function useUpdateLendiCodeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLendiCodeMutation, UpdateLendiCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLendiCodeMutation, UpdateLendiCodeMutationVariables>(UpdateLendiCodeDocument, options);
      }
export type UpdateLendiCodeMutationHookResult = ReturnType<typeof useUpdateLendiCodeMutation>;
export type UpdateLendiCodeMutationResult = Apollo.MutationResult<UpdateLendiCodeMutation>;
export type UpdateLendiCodeMutationOptions = Apollo.BaseMutationOptions<UpdateLendiCodeMutation, UpdateLendiCodeMutationVariables>;
export const RulesDocument = gql`
    query rules($limit: Int, $nextToken: String) {
  listRules(limit: $limit, nextToken: $nextToken) {
    rules {
      ...RuleFragment
    }
    nextToken
  }
}
    ${RuleFragmentFragmentDoc}`;

/**
 * __useRulesQuery__
 *
 * To run a query within a React component, call `useRulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRulesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      nextToken: // value for 'nextToken'
 *   },
 * });
 */
export function useRulesQuery(baseOptions?: Apollo.QueryHookOptions<RulesQuery, RulesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RulesQuery, RulesQueryVariables>(RulesDocument, options);
      }
export function useRulesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RulesQuery, RulesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RulesQuery, RulesQueryVariables>(RulesDocument, options);
        }
export type RulesQueryHookResult = ReturnType<typeof useRulesQuery>;
export type RulesLazyQueryHookResult = ReturnType<typeof useRulesLazyQuery>;
export type RulesQueryResult = Apollo.QueryResult<RulesQuery, RulesQueryVariables>;
export const GetIacLendersConfigDocument = gql`
    query getIacLendersConfig {
  getIacLendersConfig {
    lenders
  }
}
    `;

/**
 * __useGetIacLendersConfigQuery__
 *
 * To run a query within a React component, call `useGetIacLendersConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIacLendersConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIacLendersConfigQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetIacLendersConfigQuery(baseOptions?: Apollo.QueryHookOptions<GetIacLendersConfigQuery, GetIacLendersConfigQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetIacLendersConfigQuery, GetIacLendersConfigQueryVariables>(GetIacLendersConfigDocument, options);
      }
export function useGetIacLendersConfigLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetIacLendersConfigQuery, GetIacLendersConfigQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetIacLendersConfigQuery, GetIacLendersConfigQueryVariables>(GetIacLendersConfigDocument, options);
        }
export type GetIacLendersConfigQueryHookResult = ReturnType<typeof useGetIacLendersConfigQuery>;
export type GetIacLendersConfigLazyQueryHookResult = ReturnType<typeof useGetIacLendersConfigLazyQuery>;
export type GetIacLendersConfigQueryResult = Apollo.QueryResult<GetIacLendersConfigQuery, GetIacLendersConfigQueryVariables>;
export const ListLendicodesDocument = gql`
    query listLendicodes($limit: Int, $nextToken: String) {
  listLendiCodes(limit: $limit, nextToken: $nextToken) {
    lendiCodes {
      ...LendiCodeFragment
    }
    nextToken
  }
}
    ${LendiCodeFragmentFragmentDoc}`;

/**
 * __useListLendicodesQuery__
 *
 * To run a query within a React component, call `useListLendicodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListLendicodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListLendicodesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      nextToken: // value for 'nextToken'
 *   },
 * });
 */
export function useListLendicodesQuery(baseOptions?: Apollo.QueryHookOptions<ListLendicodesQuery, ListLendicodesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListLendicodesQuery, ListLendicodesQueryVariables>(ListLendicodesDocument, options);
      }
export function useListLendicodesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListLendicodesQuery, ListLendicodesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListLendicodesQuery, ListLendicodesQueryVariables>(ListLendicodesDocument, options);
        }
export type ListLendicodesQueryHookResult = ReturnType<typeof useListLendicodesQuery>;
export type ListLendicodesLazyQueryHookResult = ReturnType<typeof useListLendicodesLazyQuery>;
export type ListLendicodesQueryResult = Apollo.QueryResult<ListLendicodesQuery, ListLendicodesQueryVariables>;