import React, { useState, useEffect, useContext } from 'react';
import useAxios from 'axios-hooks';

interface DocTypesApiData {
  subcategoryId: string;
  subcategoryName: string;
}

export interface DocType {
  id: string;
  name: string;
}

export interface DocTypes {
  docTypes: DocType[];
}

interface DocTypeDataProviderProps {
  url: string;
}

export const DocTypeDataContext = React.createContext<DocTypes>({
  docTypes: [],
});
export const useDocTypeData = () => useContext(DocTypeDataContext);
export const DocTypeDataProvider: React.FC<DocTypeDataProviderProps> = ({
  url,
  children,
}) => {
  // query required-documents API
  const [{ data }] = useAxios(url);
  const [allDocTypes, setAllDocTypes] = useState([] as DocType[]);

  useEffect(() => {
    if (data && Array.isArray(data.data)) {
      const docTypes = data.data
        .map((v: DocTypesApiData) => {
          return {
            id: v.subcategoryId,
            name: v.subcategoryName,
          } as DocType;
        })
        .sort((docType1: DocType, docType2: DocType) =>
          docType1.name.localeCompare(docType2.name)
        );
      setAllDocTypes(docTypes);
    }
  }, [data]);

  return (
    <DocTypeDataContext.Provider
      value={{
        docTypes: allDocTypes,
      }}>
      {children}
    </DocTypeDataContext.Provider>
  );
};
