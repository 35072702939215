import React, { useState, useEffect } from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PostAddIcon from '@material-ui/icons/PostAdd';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { useSideBarContext } from './Context';
import logo from '../../lendi_logo.png';

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    appBar: {
      display: 'flex',
      height: '64px',
      'justify-content': 'center',
      'align-items': 'center',
    },
    logo: {
      height: '50px',
      'margin-right': '6px',
    },
    drawer: {
      [theme.breakpoints.up('sm')]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    drawerPaper: {
      width: drawerWidth,
    },
  })
);

const SideBar: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const [selectedPath, setSelectedPath] = useState(history.location.pathname);
  const {
    sideBarOpen,
    sideBarOpenMobile,
    setSideBarOpenMobile,
  } = useSideBarContext();

  useEffect(() => {
    setSelectedPath(history.location.pathname);
  }, [history.location.pathname, selectedPath]);

  const handleClick = (path: string) => {
    setSelectedPath(path);
    history.push(path);
  };

  const Drawers = () => (
    <List>
      <ListItem
        button
        key="rules"
        selected={selectedPath === '/'}
        onClick={() => handleClick('/')}>
        <ListItemIcon>
          <AssignmentIcon />
        </ListItemIcon>
        <ListItemText primary="All Rules" />
      </ListItem>
      <ListItem
        button
        key="new-lending-rules"
        selected={selectedPath === '/new-lending-rules'}
        onClick={() => handleClick('/new-lending-rules')}>
        <ListItemIcon>
          <AssignmentIcon />
        </ListItemIcon>
        <ListItemText primary="New Lending Rules" />
      </ListItem>
      <ListItem
        button
        key="variation-rules"
        selected={selectedPath === '/variation-rules'}
        onClick={() => handleClick('/variation-rules')}>
        <ListItemIcon>
          <AssignmentIcon />
        </ListItemIcon>
        <ListItemText primary="Variation Rules" />
      </ListItem>
      {process.env.REACT_APP_ENV !== 'production' && (
        <ListItem
          button
          key="new-rule"
          selected={selectedPath === '/new-rule'}
          onClick={() => handleClick('/new-rule')}>
          <ListItemIcon>
            <PostAddIcon />
          </ListItemIcon>
          <ListItemText primary="Add New Rule" />
        </ListItem>
      )}
      <ListItem
        button
        key="lendi-code-management"
        selected={selectedPath === '/lendi-code-management'}
        onClick={() => handleClick('/lendi-code-management')}>
        <ListItemIcon>
          <AssignmentIcon />
        </ListItemIcon>
        <ListItemText primary="Lendi Code Management" />
      </ListItem>
      <ListItem
        button
        key="iac-lenders"
        selected={selectedPath === '/iac-lenders'}
        onClick={() => handleClick('/iac-lenders')}>
        <ListItemIcon>
          <AssignmentIcon />
        </ListItemIcon>
        <ListItemText primary="IAC Lenders" />
      </ListItem>
    </List>
  );

  return (
    <nav className={classes.root}>
      <Hidden xsUp implementation="css">
        <Drawer
          className={classes.drawer}
          variant="temporary"
          classes={{
            paper: classes.drawerPaper,
          }}
          open={sideBarOpenMobile}
          onClose={() => setSideBarOpenMobile(false)}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          anchor="left">
          <div className={classes.appBar}>
            <img src={logo} className={classes.logo} alt="logo" />
          </div>
          <Divider />
          <Drawers />
        </Drawer>
      </Hidden>

      <Hidden xsDown implementation="css">
        <Drawer
          className={classes.drawer}
          variant="persistent"
          classes={{
            paper: classes.drawerPaper,
          }}
          open={sideBarOpen}
          anchor="left">
          <div className={classes.appBar}>
            <img src={logo} className={classes.logo} alt="logo" />
          </div>
          <Divider />
          <Drawers />
        </Drawer>
      </Hidden>
    </nav>
  );
};

export default SideBar;
