import React from 'react';
import { ApolloProvider } from '@apollo/client';
import client from '../client/apollo';

export const withApolloProvider = <P extends object>(
  Component: React.ComponentType<P>
) => {
  return (props: any) => {
    return (
      <ApolloProvider client={client}>
        <Component {...(props as P)} />
      </ApolloProvider>
    );
  };
};
