import { Category, Rule } from '../../../graphql/types';
import { BooleanFlagSearchFilter } from './SearchFilterDetail';

//Case Insensitive
export const stringFilterPredicate = (string: string, stringFilter: string) => {
  return stringFilter
    ? string.toLowerCase().includes(stringFilter.toLowerCase())
    : true;
};

export const booleanFilterPredicate = (
  value: boolean,
  booleanFilter: BooleanFlagSearchFilter
) => {
  if (!booleanFilter.isFilterOn) return true;
  else return value === booleanFilter.booleanFilteredFor;
};

export const idFilterPredicate = (id: string, idFilter: string) => {
  return stringFilterPredicate(id, idFilter);
};

export const nameFilterPredicate = (name: string, nameFilter: string) => {
  return stringFilterPredicate(name, nameFilter);
};

export const descriptionFilterPredicate = (
  description: string,
  descriptionFilter: string
) => {
  return stringFilterPredicate(description, descriptionFilter);
};

export const categoryFilterPredicate = (
  category: Category,
  categoryFilter?: Category
) => {
  return categoryFilter ? category === categoryFilter : true;
};

export const activeFilterPredicate = (
  active: boolean,
  activeFilter: BooleanFlagSearchFilter
) => {
  return booleanFilterPredicate(active, activeFilter);
};

export const allowBrokerMitigationFilterPredicate = (
  allowBrokerMitigation: boolean,
  allowBrokerMitigationFilter: BooleanFlagSearchFilter
) => {
  return booleanFilterPredicate(
    allowBrokerMitigation,
    allowBrokerMitigationFilter
  );
};

export const allowCustomerMitigationFilterPredicate = (
  allowCustomerMitigation: boolean,
  allowCustomerMitigationFilter: BooleanFlagSearchFilter
) => {
  return booleanFilterPredicate(
    allowCustomerMitigation,
    allowCustomerMitigationFilter
  );
};

export const includedInCoversheetFilterPredicate = (
  includedInCoversheet: boolean,
  includedInCoversheetFilter: BooleanFlagSearchFilter
) => {
  return booleanFilterPredicate(
    includedInCoversheet,
    includedInCoversheetFilter
  );
};

export const singleConditionFilterPredicate = (
  singleCondition: boolean,
  singleConditionFilter: BooleanFlagSearchFilter
) => {
  return booleanFilterPredicate(singleCondition, singleConditionFilter);
};

export const appliesToNewLenderFilterPredicate = (
  appliesToNewLender: boolean,
  appliesToNewLenderFilter: BooleanFlagSearchFilter
) => {
  return booleanFilterPredicate(appliesToNewLender, appliesToNewLenderFilter);
};

export const lenderFilterPredicate = (
  rule: Rule,
  lenderIdsFilter: string[],
  appliesToAllLenders: boolean
) => {
  if (appliesToAllLenders) return rule.mitigations.length === 0;
  if (lenderIdsFilter.length === 0) return true;
  else
    return rule.mitigations.some(mitigation =>
      lenderIdsFilter.every(idFilter => mitigation.lenders.includes(idFilter))
    );
};

export const mitigationDescriptionFilterPredicate = (
  rule: Rule,
  mitigationDescriptionFilter: string
) => {
  if (!mitigationDescriptionFilter) return true;
  const defaultMitigationTest = descriptionFilterPredicate(
    rule.defaultMitigation.description
      ? rule.defaultMitigation.description
      : '',
    mitigationDescriptionFilter
  );
  const mitigationTest = rule.mitigations.some(mitigation =>
    descriptionFilterPredicate(
      mitigation.description ? mitigation.description : '',
      mitigationDescriptionFilter
    )
  );
  return defaultMitigationTest || mitigationTest;
};

export const docTypeFilterPredicate = (
  rule: Rule,
  docTypeIdsFilter: string[]
) => {
  if (docTypeIdsFilter.length === 0) {
    return true;
  }
  const defaultMitigationDocTypeTest = rule.defaultMitigation
    .mitigationRequirement
    ? docTypeIdsFilter.every(idFilter =>
        rule.defaultMitigation.mitigationRequirement!.requiredDocTypes.includes(
          idFilter
        )
      )
    : false;
  const mitigationDocTypeTest = rule.mitigations.some(mitigation =>
    mitigation.mitigationRequirement
      ? docTypeIdsFilter.every(idFilter =>
          mitigation.mitigationRequirement!.requiredDocTypes.includes(idFilter)
        )
      : false
  );
  return defaultMitigationDocTypeTest || mitigationDocTypeTest;
};

export const allowOverrideFilterPredicate = (
  rule: Rule,
  allowOverrideFilter: BooleanFlagSearchFilter
) => {
  if (!allowOverrideFilter.isFilterOn) return true;
  const nothingToMatch =
    !rule.defaultMitigation.mitigationRequirement &&
    rule.mitigations.length === 0;
  const defaultMitigationAllowOverrideTest = rule.defaultMitigation
    .mitigationRequirement
    ? rule.defaultMitigation.mitigationRequirement!.allowOverride ===
      allowOverrideFilter.booleanFilteredFor
    : false;
  const mitigationAllowOverrideTest = rule.mitigations.some(mitigation =>
    mitigation.mitigationRequirement
      ? mitigation.mitigationRequirement!.allowOverride ===
        allowOverrideFilter.booleanFilteredFor
      : false
  );
  return nothingToMatch
    ? false
    : defaultMitigationAllowOverrideTest || mitigationAllowOverrideTest;
};

export const hardstopFilterPredicate = (
  rule: Rule,
  hardstopFilter: BooleanFlagSearchFilter
) => {
  if (!hardstopFilter.isFilterOn) return true;
  const nothingToMatch =
    !rule.defaultMitigation.mitigationRequirement &&
    rule.mitigations.length === 0;
  const defaultMitigationHardstopTest = rule.defaultMitigation
    .mitigationRequirement
    ? rule.defaultMitigation.mitigationRequirement!.hardstop ===
      hardstopFilter.booleanFilteredFor
    : false;
  const mitigationHardstopTest = rule.mitigations.some(mitigation =>
    mitigation.mitigationRequirement
      ? mitigation.mitigationRequirement!.hardstop ===
        hardstopFilter.booleanFilteredFor
      : false
  );
  return nothingToMatch
    ? false
    : defaultMitigationHardstopTest || mitigationHardstopTest;
};

export const commentRequiredFilterPredicate = (
  rule: Rule,
  commentRequiredFilter: BooleanFlagSearchFilter
) => {
  if (!commentRequiredFilter.isFilterOn) return true;
  const nothingToMatch =
    !rule.defaultMitigation.mitigationRequirement &&
    rule.mitigations.length === 0;
  const defaultMitigationCommentRequiredTest = rule.defaultMitigation
    .mitigationRequirement
    ? rule.defaultMitigation.mitigationRequirement!.commentRequired ===
      commentRequiredFilter.booleanFilteredFor
    : false;
  const mitigationCommentRequiredTest = rule.mitigations.some(mitigation =>
    mitigation.mitigationRequirement
      ? mitigation.mitigationRequirement!.commentRequired ===
        commentRequiredFilter.booleanFilteredFor
      : false
  );
  return nothingToMatch
    ? false
    : defaultMitigationCommentRequiredTest || mitigationCommentRequiredTest;
};

export const variationOnlyPredicate = (
  variationOnly: boolean | null | undefined,
  variationOnlyFilter: BooleanFlagSearchFilter
) => {
  return booleanFilterPredicate(variationOnly || false, variationOnlyFilter);
};
