import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import RulesTable from './RulesTable';
import { useRulesQuery, Rule } from '../../../graphql/types';
import { useErrorDialog } from '../../../hooks/error-dialog';
import TableLoading from '../TableLoading';
import { useLocation, useHistory } from 'react-router-dom';
import SearchFilterDialog from './SearchFilterDialog';
import { SearchFilter, BooleanFlagSearchFilter } from './SearchFilterDetail';
import {
  allowCustomerMitigationFilterPredicate,
  allowBrokerMitigationFilterPredicate,
  activeFilterPredicate,
  categoryFilterPredicate,
  descriptionFilterPredicate,
  commentRequiredFilterPredicate,
  hardstopFilterPredicate,
  allowOverrideFilterPredicate,
  docTypeFilterPredicate,
  mitigationDescriptionFilterPredicate,
  singleConditionFilterPredicate,
  appliesToNewLenderFilterPredicate,
  includedInCoversheetFilterPredicate,
  lenderFilterPredicate,
  nameFilterPredicate,
  idFilterPredicate,
  variationOnlyPredicate,
} from './SearchFilters';

const Container = styled.div`
  width: calc(100% - 40px);
  margin: 20px 20px;
  overflow-x: hidden;
`;

export const defaultBooleanFlagSearchFilter: BooleanFlagSearchFilter = {
  isFilterOn: false,
  booleanFilteredFor: true,
} as BooleanFlagSearchFilter;

export const emptySearchFilter = {
  id: '',
  name: '',
  description: '',
  category: undefined,
  active: defaultBooleanFlagSearchFilter,
  allowBrokerMitigation: defaultBooleanFlagSearchFilter,
  allowCustomerMitigation: defaultBooleanFlagSearchFilter,
  includedInCoversheet: defaultBooleanFlagSearchFilter,
  singleCondition: defaultBooleanFlagSearchFilter,
  appliesToNewLender: defaultBooleanFlagSearchFilter,
  lenderIds: [],
  mitigationDescription: '',
  docTypeIds: [],
  appliesToAllLenders: false,
  allowOverride: defaultBooleanFlagSearchFilter,
  hardstop: defaultBooleanFlagSearchFilter,
  commentRequired: defaultBooleanFlagSearchFilter,
  variationOnly: defaultBooleanFlagSearchFilter,
} as SearchFilter;

interface RulesScreenProps {
  rulesTableName?: string;
  variationOnly?: boolean;
}

const QUERY_LIMIT = 400;

/**
 * Component to create Rules List
 * @param rulesTableName custom naming for rules table
 * @param variationOnly boolean or undefined, all rules will be display when variationOnly is undefined, otherwise it will filtered by variationOnly
 * @constructor
 */
const RulesScreen: React.FC<RulesScreenProps> = ({
  rulesTableName,
  variationOnly,
}) => {
  const location = useLocation();
  const errorDialog = useErrorDialog();
  const result = useRulesQuery({
    variables: {
      limit: QUERY_LIMIT,
    },
  });
  const { error, loading, refetch, fetchMore } = result;
  const [searchFilterDialog, setSearchFilterDialog] = useState(false);
  const [queryResult, setQueryResult] = useState(result?.data);
  const history = useHistory();

  const [filter, setFilter] = useState(emptySearchFilter);

  const onApplyFilter = (searchFilter: SearchFilter) => {
    setFilter(searchFilter);
    setSearchFilterDialog(false);
    history.push('/');
  };

  const applySearchFilter = (rules: Rule[]) => {
    return rules
      .filter(rule => idFilterPredicate(rule.id, filter.id))
      .filter(rule => nameFilterPredicate(rule.name, filter.name))
      .filter(rule =>
        descriptionFilterPredicate(rule.description, filter.description)
      )
      .filter(rule => categoryFilterPredicate(rule.category, filter.category))
      .filter(rule => activeFilterPredicate(rule.active, filter.active))
      .filter(rule =>
        allowBrokerMitigationFilterPredicate(
          rule.allowBrokerMitigation,
          filter.allowBrokerMitigation
        )
      )
      .filter(rule =>
        allowCustomerMitigationFilterPredicate(
          rule.allowCustomerMitigation,
          filter.allowCustomerMitigation
        )
      )
      .filter(rule =>
        includedInCoversheetFilterPredicate(
          rule.includedInCoversheet,
          filter.includedInCoversheet
        )
      )
      .filter(rule =>
        singleConditionFilterPredicate(
          rule.singleCondition,
          filter.singleCondition
        )
      )
      .filter(rule =>
        appliesToNewLenderFilterPredicate(
          rule.appliesToNewLender,
          filter.appliesToNewLender
        )
      )
      .filter(rule =>
        lenderFilterPredicate(
          rule,
          filter.lenderIds,
          filter.appliesToAllLenders
        )
      )
      .filter(rule =>
        mitigationDescriptionFilterPredicate(rule, filter.mitigationDescription)
      )
      .filter(rule => docTypeFilterPredicate(rule, filter.docTypeIds))
      .filter(rule => allowOverrideFilterPredicate(rule, filter.allowOverride))
      .filter(rule => hardstopFilterPredicate(rule, filter.hardstop))
      .filter(rule =>
        commentRequiredFilterPredicate(rule, filter.commentRequired)
      )
      .filter(rule =>
        variationOnlyPredicate(rule.variationOnly, filter.variationOnly)
      );
  };

  const filteredRules = (rules: Rule[]): Rule[] => {
    if (variationOnly === undefined) {
      return rules;
    }

    if (variationOnly) {
      return rules.filter(rule => rule.variationOnly === true);
    }

    return rules.filter(rule => rule.variationOnly !== true);
  };

  useEffect(() => {
    setQueryResult(result.data);
  }, [result]);

  useEffect(() => {
    const state = location.state ?? ({} as any);
    if (state.refresh) {
      refetch().catch(_ => {
        errorDialog?.showErrorDialog(true);
      });
    }

    if (state.searchDialog) {
      setSearchFilterDialog(true);
    } else {
      setSearchFilterDialog(false);
    }
  }, [location.state, errorDialog, refetch]);

  useEffect(() => {
    if (queryResult?.listRules.nextToken) {
      fetchMore({
        updateQuery: (previousQueryResult, { fetchMoreResult }) => {
          if (fetchMoreResult?.listRules?.rules) {
            const newResult = {
              ...previousQueryResult,
            };
            newResult.listRules = {
              ...newResult.listRules,
              rules: [
                ...newResult.listRules.rules,
                ...fetchMoreResult.listRules.rules,
              ],
            };
            newResult.listRules.nextToken = fetchMoreResult.listRules.nextToken;
            setQueryResult(newResult);
            return newResult;
          } else {
            setQueryResult(previousQueryResult);
            return previousQueryResult;
          }
        },
        variables: {
          nextToken: queryResult?.listRules.nextToken,
        },
      }).catch(_ => {
        errorDialog?.showErrorDialog(true);
      });
    }
  }, [queryResult, fetchMore, errorDialog]);

  if (error) {
    errorDialog?.showErrorDialog(true);
    return <div />;
  }

  if (loading) {
    return <TableLoading />;
  }

  return (
    <Container>
      <RulesTable
        rules={applySearchFilter(
          filteredRules(queryResult?.listRules.rules || [])
        )}
        refetch={refetch}
        rulesTableName={rulesTableName}
      />
      {searchFilterDialog ? (
        <SearchFilterDialog
          onApplyFilter={searchFilter => onApplyFilter(searchFilter)}
          currentlyAppliedFilter={filter}
        />
      ) : (
        undefined
      )}
    </Container>
  );
};

export default RulesScreen;
