import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useDocTypeData } from '../hooks/doctypes';

interface DocTypeSelectItem {
  id: string;
  name: string;
}

interface DocTypeMultiSelectProps {
  label?: string;
  selectedDocTypeUUIDs: string[];
  onSelectedDocTypes: (value: string[]) => void;
}

const DocTypeMultiSelect: React.FC<DocTypeMultiSelectProps> = ({
  label,
  selectedDocTypeUUIDs,
  onSelectedDocTypes,
}) => {
  const { docTypes } = useDocTypeData();
  const [allDocTypes, setAllDocTypes] = useState([] as DocTypeSelectItem[]);
  const [selected, setSelected] = useState([] as DocTypeSelectItem[]);
  const handleChange = (_: React.ChangeEvent<{}>, value: any) => {
    setSelected(value);
    onSelectedDocTypes(
      (value as DocTypeSelectItem[]).map(docType => docType.id)
    );
  };
  useEffect(() => {
    setAllDocTypes(docTypes);
  }, [docTypes]);

  useEffect(() => {
    // always selected from known doc types
    const getDocTypeById = (id: string) =>
      allDocTypes && allDocTypes.find(docType => docType.id === id)!;

    if (selectedDocTypeUUIDs) {
      setSelected(
        selectedDocTypeUUIDs.map(id => getDocTypeById(id)).filter(v => v)
      );
    }
  }, [allDocTypes, selectedDocTypeUUIDs]);

  return (
    <Autocomplete
      multiple
      size="small"
      options={allDocTypes}
      getOptionLabel={option => option.name}
      value={selected}
      filterSelectedOptions
      onChange={handleChange}
      renderInput={params => (
        <TextField
          {...params}
          size="small"
          variant="standard"
          label={label === undefined ? 'DocTypes' : label}
          fullWidth
        />
      )}
    />
  );
};

export default DocTypeMultiSelect;
