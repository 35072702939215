import { useFormContext } from 'react-hook-form';
import { Alert } from '@material-ui/lab';
import DefaultMitigationPolicy from './DefaultMitigationPolicy';
import { Typography } from '@material-ui/core';
import React from 'react';
import MitigationTable from './MitigationTable';
import { Rule } from './index';
import styled from 'styled-components';

const DefaultMitigationContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  width: 100%;
`;

const DefaultMitigationTitle = styled.div`
  margin: 10px 0px;
  font-size: 1em;
  flex-wrap: wrap;
`;

const DefaultMitigationContent = styled.div`
  display: flex;
  justify-content: space-between;
`;

const DefaultMitigationInfo = styled.div`
  flex: 1;
  padding: 0px 20px;
`;

interface MitigationTabPanelProps {
  forNewLender: boolean;
  rule: Rule;
}

const MitigationTabPanel: React.FC<MitigationTabPanelProps> = ({
  forNewLender,
  rule,
}) => {
  const {
    setValue,
    formState: { errors },
  } = useFormContext();

  return (
    <React.Fragment>
      {forNewLender && (
        <MitigationTable currentMitigations={rule.mitigations} />
      )}

      <DefaultMitigationContainer>
        <DefaultMitigationTitle>
          <Typography>Default mitigation policy</Typography>
        </DefaultMitigationTitle>
        <DefaultMitigationContent>
          <DefaultMitigationInfo>
            The rest of lenders that are not configured in mitigation policies
            would be handled by default mitigation policy.
          </DefaultMitigationInfo>
          <DefaultMitigationPolicy
            currentDefaultMitigation={rule.defaultMitigation}
            onDefaultMitigationChange={defaultMitigation => {
              setValue('defaultMitigation', defaultMitigation);
            }}
          />
        </DefaultMitigationContent>
      </DefaultMitigationContainer>

      {errors.defaultMitigation && (
        <Alert severity="warning">Mitigation should be set</Alert>
      )}
    </React.Fragment>
  );
};

const mitigationTabPanelRender = (props: MitigationTabPanelProps) => (
  <MitigationTabPanel {...props} />
);
export { MitigationTabPanel as default, mitigationTabPanelRender };
