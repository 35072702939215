import React, { useEffect } from 'react';
import { Mitigation } from '../../graphql/types';
import TextField from '@material-ui/core/TextField';
import { useFormContext, Controller } from 'react-hook-form';
import { Alert } from '@material-ui/lab';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import DocTypeMultiSelect from '../DocTypeMultiSelect';
import LenderMultiSelect from '../LenderMultiSelect';

interface MitigationItemProps {
  mitigation: Mitigation;
  index: number;
}

const MitigationTableItem: React.FC<MitigationItemProps> = ({
  mitigation,
  index,
}) => {
  const {
    register,
    setValue,
    formState: { errors },
    getValues,
  } = useFormContext();

  useEffect(() => {
    register(`mitigations.${index}.description`, { required: false });
    register(`mitigations.${index}.lenders`, { required: true });
    register(`mitigations.${index}.mitigationRequirement.requiredDocTypes`, {
      required: false,
    });

    // due to restriction below, we need to initialise requiredDocTypes data in form state to empty array
    // on rendering when the requiredDocTypes is not set(undefined)
    // 1. docTypes is an uncontrolled component,
    // 2. requiredDocTypes is an optional field
    // 3. gql requiredDocTypes is [String!]! field
    const docTypes = getValues(
      `mitigations.${index}.mitigationRequirement.requiredDocTypes`
    );
    if (!docTypes) {
      setValue(
        `mitigations.${index}.mitigationRequirement.requiredDocTypes`,
        []
      );
    }
  }, [index, register, getValues, setValue]);

  return (
    <React.Fragment>
      <LenderMultiSelect
        label="Lenders for mitigation"
        selectedLenderUUIDs={mitigation.lenders}
        onSelectedLenders={lenders => {
          setValue(`mitigations.${index}.lenders`, lenders);
        }}
      />
      {errors.mitigations && errors.mitigations[index].lenders && (
        <Alert severity="warning">Mitigation lender(s) required!</Alert>
      )}
      <Controller
        name={`mitigations.${index}.description`}
        defaultValue={mitigation.description || ''}
        render={({ field }) => (
          <TextField
            label="Mitigation description "
            multiline
            minRows={2}
            maxRows={2}
            fullWidth
            {...field}
          />
        )}
      />
      <Controller
        name={`mitigations.${index}.mitigationRequirement.allowOverride`}
        defaultValue={mitigation.mitigationRequirement!.allowOverride}
        render={({ field }) => (
          <FormControlLabel
            control={
              <Checkbox
                checked={field.value}
                onChange={e => field.onChange(e.target.checked)}
              />
            }
            label="Policy can be overridden"
          />
        )}
      />
      <Controller
        name={`mitigations.${index}.mitigationRequirement.hardstop`}
        defaultValue={mitigation.mitigationRequirement!.hardstop}
        render={({ field }) => (
          <FormControlLabel
            control={
              <Checkbox
                checked={field.value}
                onChange={e => field.onChange(e.target.checked)}
              />
            }
            label="Can not be mitigated"
          />
        )}
      />

      <Controller
        name={`mitigations.${index}.mitigationRequirement.commentRequired`}
        defaultValue={mitigation.mitigationRequirement!.commentRequired}
        render={({ field }) => (
          <FormControlLabel
            control={
              <Checkbox
                checked={field.value}
                onChange={e => field.onChange(e.target.checked)}
              />
            }
            label="Requires comment"
          />
        )}
      />
      <DocTypeMultiSelect
        onSelectedDocTypes={docTypes => {
          setValue(
            `mitigations.${index}.mitigationRequirement.requiredDocTypes`,
            docTypes
          );
        }}
        selectedDocTypeUUIDs={
          mitigation.mitigationRequirement!.requiredDocTypes
        }
      />
    </React.Fragment>
  );
};

export default MitigationTableItem;
