import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { DialogTitle } from '@material-ui/core';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import styled from 'styled-components';
// @ts-ignore
import SearchFilterDetail, { SearchFilter } from './SearchFilterDetail';
import { emptySearchFilter } from './index';
import Transition from '../Transition';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

interface SearchFilterDialogProps {
  currentlyAppliedFilter: SearchFilter;
  onApplyFilter: (searchFilter: SearchFilter) => void;
}

const SearchFilterDialog: React.FC<SearchFilterDialogProps> = ({
  currentlyAppliedFilter,
  onApplyFilter,
}) => {
  const [searchFilter, setSearchFilter] = useState({
    ...currentlyAppliedFilter,
  });

  const [open, setOpen] = useState(true);
  const onApplyClicked = (searchFilter: SearchFilter) => {
    setOpen(false);
    onApplyFilter(searchFilter);
  };
  const onClearFilterClicked = () => {
    setOpen(false);
    onApplyFilter(emptySearchFilter);
  };

  return (
    <Container>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        fullWidth
        maxWidth="lg">
        <DialogTitle>Search filters</DialogTitle>
        <DialogContent>
          <SearchFilterDetail
            searchFilter={searchFilter}
            onSearchFilterChange={searchFilter => setSearchFilter(searchFilter)}
          />
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => onClearFilterClicked()}>
            Clear
          </Button>
          <Button
            color="secondary"
            onClick={() => onApplyClicked(searchFilter)}>
            Apply
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default SearchFilterDialog;
