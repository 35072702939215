import * as monaco from 'monaco-editor';
import factSchemas from './fact-schemas';

export const ruleDescription = 'rdesc';

const factLevelCompletionItems = (facts: any[], range: any) => {
  return facts.map(fact => {
    const insertedText = fact.name;
    const label = fact.name;
    return {
      label: label,
      kind: monaco.languages.CompletionItemKind.Field,
      insertText: insertedText,
      insertTextRules:
        monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
      range: range,
    };
  });
};

const propertyLevelCompletionItem = (factSchema: any, range: any) => {
  return factSchema.properties.map((property: any) => {
    const insertedText = property.name + '}';
    const label = property.name;
    return {
      label: label,
      kind: monaco.languages.CompletionItemKind.Property,
      insertText: insertedText,
      insertTextRules:
        monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
      range: range,
    };
  });
};

monaco.languages.register({ id: ruleDescription });

monaco.languages.registerCompletionItemProvider(ruleDescription, {
  provideCompletionItems: (model, position) => {
    const textUntilPosition = model.getValueInRange({
      startLineNumber: position.lineNumber,
      startColumn: 1,
      endLineNumber: position.lineNumber,
      endColumn: position.column,
    });
    // eslint-disable-next-line
    const match = textUntilPosition.match(new RegExp('\\${'));
    if (!match) {
      return {
        suggestions: [],
      };
    }
    const word = model.getWordUntilPosition(position);
    const range = {
      startLineNumber: position.lineNumber,
      endLineNumber: position.lineNumber,
      startColumn: word.startColumn,
      endColumn: word.endColumn,
    };
    return {
      suggestions: factLevelCompletionItems(factSchemas, range),
    };
  },
  triggerCharacters: ['{'],
});

monaco.languages.registerCompletionItemProvider(ruleDescription, {
  provideCompletionItems: function(model, position) {
    const suggestions = factSchemas.map(factSchema => {
      const textUntilPosition = model.getValueInRange({
        startLineNumber: position.lineNumber,
        startColumn: 1,
        endLineNumber: position.lineNumber,
        endColumn: position.column,
      });
      // eslint-disable-next-line
      const match = textUntilPosition.match(new RegExp(factSchema.name + '.$'));
      if (!match) {
        return [];
      }
      const word = model.getWordUntilPosition(position);
      const range = {
        startLineNumber: position.lineNumber,
        endLineNumber: position.lineNumber,
        startColumn: word.startColumn,
        endColumn: word.endColumn,
      };
      return propertyLevelCompletionItem(factSchema, range);
    });
    return {
      suggestions: suggestions.flat(),
    };
  },
  triggerCharacters: ['.'],
});
