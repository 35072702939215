import React, { useState, useEffect, useContext } from 'react';
import useAxios from 'axios-hooks';

interface FunderApiData {
  id: string;
  attributes: {
    name: string;
  };
}

export interface Lender {
  id: string;
  name: string;
}

export interface Lenders {
  lenders: Lender[];
}

interface LenderDataProviderProps {
  url: string;
}

export const LenderDataContext = React.createContext<Lenders>({ lenders: [] });
export const useLenderData = () => useContext(LenderDataContext);
export const LenderDataProvider: React.FC<LenderDataProviderProps> = ({
  url,
  children,
}) => {
  // query Funders JSON-API with page limit 100 from environment var REACT_APP_FUNDERS_API_URL
  // if the number of total funders is greater than 100, some refactors would be required
  const [{ data }] = useAxios(url);
  const [allLenders, setAllLenders] = useState([] as Lender[]);

  useEffect(() => {
    if (data && Array.isArray(data.data)) {
      const lenders = data.data
        .map((v: FunderApiData) => {
          return {
            id: v.id,
            name: v.attributes.name,
          } as Lender;
        })
        .sort((lender1: Lender, lender2: Lender) =>
          lender1.name.localeCompare(lender2.name)
        );
      setAllLenders(lenders);
    }
  }, [data]);

  return (
    <LenderDataContext.Provider
      value={{
        lenders: allLenders,
      }}>
      {children}
    </LenderDataContext.Provider>
  );
};
