const { Parser } = require('json2csv');
const json2csvParser = new Parser();

/**
 * Coverting JSON object to csv content
 * @param data json format data
 */
export const convertJson2CsvContent = (data: any): Blob => {
  const csvContent = json2csvParser.parse(data);
  return new Blob([csvContent], {
    type: 'text/csv;charset=utf-8',
  });
};
