import TextField from '@material-ui/core/TextField';
import FilterCheckBox from './FilterCheckBox';
import LenderMultiSelect from '../../LenderMultiSelect';
import React, { useState } from 'react';
import styled from 'styled-components';
import DocTypeMultiSelect from '../../DocTypeMultiSelect';
import CategorySelect from '../../RuleDetail/CategorySelect';
import { Typography } from '@material-ui/core';
import { Category } from '../../../graphql/types';
import SimpleCheckBox from './SimpleCheckBox';

export interface SearchFilter {
  id: string;
  name: string;
  description: string;
  category?: Category;
  active: BooleanFlagSearchFilter;
  allowBrokerMitigation: BooleanFlagSearchFilter;
  allowCustomerMitigation: BooleanFlagSearchFilter;
  includedInCoversheet: BooleanFlagSearchFilter;
  singleCondition: BooleanFlagSearchFilter;
  appliesToNewLender: BooleanFlagSearchFilter;
  lenderIds: string[];
  mitigationDescription: string;
  docTypeIds: string[];
  appliesToAllLenders: boolean;
  allowOverride: BooleanFlagSearchFilter;
  hardstop: BooleanFlagSearchFilter;
  commentRequired: BooleanFlagSearchFilter;
  variationOnly: BooleanFlagSearchFilter;
}

export interface BooleanFlagSearchFilter {
  isFilterOn: boolean;
  booleanFilteredFor: boolean;
}

interface SearchFilterDetailProps {
  searchFilter: SearchFilter;
  onSearchFilterChange: (searchFilter: SearchFilter) => void;
}

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
`;

const ColumnChild = styled.div`
  flex: 1;
  padding: 1em;
`;

const FilterItem = styled.div`
  padding: 0.5em 0em;
`;

const SearchFilterDetail: React.FC<SearchFilterDetailProps> = ({
  searchFilter,
  onSearchFilterChange,
}) => {
  const [id, setId] = useState(searchFilter.id);
  const [name, setName] = useState(searchFilter.name);
  const [description, setDescription] = useState(searchFilter.description);
  const [category, setCategory] = useState(searchFilter.category);
  const [active, setActive] = useState(searchFilter.active);
  const [allowBrokerMitigation, setAllowBrokerMitigation] = useState(
    searchFilter.allowBrokerMitigation
  );
  const [allowCustomerMitigation, setAllowCustomerMitigation] = useState(
    searchFilter.allowCustomerMitigation
  );
  const [includedInCoversheet, setIncludedInCoversheet] = useState(
    searchFilter.includedInCoversheet
  );
  const [singleCondition, setSingleCondition] = useState(
    searchFilter.singleCondition
  );
  const [appliesToNewLender, setAppliesToNewLender] = useState(
    searchFilter.appliesToNewLender
  );
  const [lenderIds, setLenderIds] = useState(searchFilter.lenderIds);
  const [mitigationDescription, setMitigationDescription] = useState(
    searchFilter.mitigationDescription
  );
  const [docTypeIds, setDocTypeIds] = useState(searchFilter.docTypeIds);
  const [appliesToAllLenders, setAppliesToAllLenders] = useState(
    searchFilter.appliesToAllLenders
  );
  const [allowOverride, setAllowOverride] = useState(
    searchFilter.allowOverride
  );
  const [hardstop, setHardstop] = useState(searchFilter.hardstop);
  const [variationOnly, setVariationOnly] = useState(
    searchFilter.variationOnly
  );

  const [commentRequired, setCommentRequired] = useState(
    searchFilter.commentRequired
  );

  const handleIDChange = (value: string) => {
    setId(value);
    searchFilter.id = value;
    onSearchFilterChange(searchFilter);
  };

  const handleNameChange = (value: string) => {
    setName(value);
    searchFilter.name = value;
    onSearchFilterChange(searchFilter);
  };

  const handleDescriptionChange = (value: string) => {
    setDescription(value);
    searchFilter.description = value;
    onSearchFilterChange(searchFilter);
  };

  const handleCategorySelect = (value: string | null) => {
    const category = value
      ? Category[value as keyof typeof Category]
      : undefined;
    setCategory(category);
    searchFilter.category = category;
    onSearchFilterChange(searchFilter);
  };

  const handleActiveChange = (value: BooleanFlagSearchFilter) => {
    setActive(value);
    searchFilter.active = value;
    onSearchFilterChange(searchFilter);
  };

  const handleAllowBrokerMitigationChange = (
    value: BooleanFlagSearchFilter
  ) => {
    setAllowBrokerMitigation(value);
    searchFilter.allowBrokerMitigation = value;
    onSearchFilterChange(searchFilter);
  };

  const handleAllowCustomerMitigationChange = (
    value: BooleanFlagSearchFilter
  ) => {
    setAllowCustomerMitigation(value);
    searchFilter.allowCustomerMitigation = value;
    onSearchFilterChange(searchFilter);
  };

  const handleIncludedInCoversheetChange = (value: BooleanFlagSearchFilter) => {
    setIncludedInCoversheet(value);
    searchFilter.includedInCoversheet = value;
    onSearchFilterChange(searchFilter);
  };

  const handleSingleConditionChange = (value: BooleanFlagSearchFilter) => {
    setSingleCondition(value);
    searchFilter.singleCondition = value;
    onSearchFilterChange(searchFilter);
  };

  const handleAppliesToNewLender = (value: BooleanFlagSearchFilter) => {
    setAppliesToNewLender(value);
    searchFilter.appliesToNewLender = value;
    onSearchFilterChange(searchFilter);
  };

  const handleLenderIdsChange = (values: string[]) => {
    setLenderIds(values);
    searchFilter.lenderIds = values;
    onSearchFilterChange(searchFilter);
  };

  const handleMitigationDescriptionChange = (value: string) => {
    setMitigationDescription(value);
    searchFilter.mitigationDescription = value;
    onSearchFilterChange(searchFilter);
  };

  const handleDocTypeIdsChange = (values: string[]) => {
    setDocTypeIds(values);
    searchFilter.docTypeIds = values;
    onSearchFilterChange(searchFilter);
  };

  const handleAppliesToAllLenders = (value: boolean) => {
    setAppliesToAllLenders(value);
    searchFilter.appliesToAllLenders = value;
    onSearchFilterChange(searchFilter);
  };

  const handleAllowOverriddenChange = (value: BooleanFlagSearchFilter) => {
    setAllowOverride(value);
    searchFilter.allowOverride = value;
    onSearchFilterChange(searchFilter);
  };

  const handleHardstopChange = (value: BooleanFlagSearchFilter) => {
    setHardstop(value);
    searchFilter.hardstop = value;
    onSearchFilterChange(searchFilter);
  };

  const handleCommentRequiredChange = (value: BooleanFlagSearchFilter) => {
    setCommentRequired(value);
    searchFilter.commentRequired = value;
    onSearchFilterChange(searchFilter);
  };

  const handleVariationOnlyChange = (value: BooleanFlagSearchFilter) => {
    setVariationOnly(value);
    searchFilter.variationOnly = value;
    onSearchFilterChange(searchFilter);
  };

  return (
    <ColumnContainer>
      <ColumnChild>
        <Typography component="h2">Rule filters</Typography>
        <FilterItem>
          <TextField
            label="ID"
            fullWidth
            value={id || ''}
            onChange={event => handleIDChange(event.target.value)}
          />
        </FilterItem>
        <FilterItem>
          <TextField
            label="Name"
            fullWidth
            value={name || ''}
            onChange={event => handleNameChange(event.target.value)}
          />
        </FilterItem>
        <FilterItem>
          <TextField
            label="Description"
            fullWidth
            value={description || ''}
            onChange={event => handleDescriptionChange(event.target.value)}
          />
        </FilterItem>
        <FilterItem>
          <CategorySelect
            currentSelected={category?.valueOf()}
            onCategorySelected={category => handleCategorySelect(category)}
          />
        </FilterItem>
        <FilterItem>
          <FilterCheckBox
            label="Active"
            description="Is rule active"
            currentValue={active}
            onCheckChange={value => handleActiveChange(value)}
          />
        </FilterItem>
        <FilterItem>
          <FilterCheckBox
            label="Broker mitigatable"
            description="Is rule broker mitigatable"
            currentValue={allowBrokerMitigation}
            onCheckChange={value => handleAllowBrokerMitigationChange(value)}
          />
        </FilterItem>
        <FilterItem>
          <FilterCheckBox
            label="Customer mitigatable"
            description="Is rule customer mitigatable"
            currentValue={allowCustomerMitigation}
            onCheckChange={value => handleAllowCustomerMitigationChange(value)}
          />
        </FilterItem>
        <FilterItem>
          <FilterCheckBox
            label="Included in coversheet"
            description="Condition is included in coversheet"
            currentValue={includedInCoversheet}
            onCheckChange={value => handleIncludedInCoversheetChange(value)}
          />
        </FilterItem>
        <FilterItem>
          <FilterCheckBox
            label="Single condition"
            description="Rule generates only one condition"
            currentValue={singleCondition}
            onCheckChange={value => handleSingleConditionChange(value)}
          />
        </FilterItem>
        <FilterItem>
          <FilterCheckBox
            label="For new lender"
            description="Rule applies to new lender (false for current lender)"
            currentValue={appliesToNewLender}
            onCheckChange={value => handleAppliesToNewLender(value)}
          />
        </FilterItem>
      </ColumnChild>
      <ColumnChild>
        <Typography component="h2">Mitigation filters</Typography>
        <FilterItem>
          <LenderMultiSelect
            disabled={appliesToAllLenders}
            label="Lenders for mitigation"
            selectedLenderUUIDs={lenderIds}
            onSelectedLenders={lenders => handleLenderIdsChange(lenders)}
          />
        </FilterItem>
        <FilterItem>
          <TextField
            label="Mitigation Description"
            fullWidth
            value={mitigationDescription}
            onChange={event =>
              handleMitigationDescriptionChange(event.target.value)
            }
          />
        </FilterItem>
        <FilterItem>
          <DocTypeMultiSelect
            label="Doc type"
            selectedDocTypeUUIDs={docTypeIds}
            onSelectedDocTypes={docTypes => handleDocTypeIdsChange(docTypes)}
          />
        </FilterItem>
        <FilterItem>
          <SimpleCheckBox
            label="Apply to all lenders"
            description="Rule applies to all lenders"
            currentValue={appliesToAllLenders}
            onCheckChange={value => handleAppliesToAllLenders(value)}
          />
        </FilterItem>
        <FilterItem>
          <FilterCheckBox
            label="Overridden"
            description="Policy can be overridden"
            currentValue={allowOverride}
            onCheckChange={value => handleAllowOverriddenChange(value)}
          />
        </FilterItem>
        <FilterItem>
          <FilterCheckBox
            label="Hardstop"
            description="Can not be mitigated"
            currentValue={hardstop}
            onCheckChange={value => handleHardstopChange(value)}
          />
        </FilterItem>
        <FilterItem>
          <FilterCheckBox
            label="Comment required"
            description="Comment required"
            currentValue={commentRequired}
            onCheckChange={value => handleCommentRequiredChange(value)}
          />
        </FilterItem>
        <FilterItem>
          <FilterCheckBox
            label="Variation only"
            description="Variation only"
            currentValue={variationOnly}
            onCheckChange={value => handleVariationOnlyChange(value)}
          />
        </FilterItem>
      </ColumnChild>
    </ColumnContainer>
  );
};

export default SearchFilterDetail;
