import { createContext, useContext } from 'react';

interface SideBarContextProps {
  sideBarOpen: boolean;
  setSideBarOpen: (open: boolean) => void;
  sideBarOpenMobile: boolean;
  setSideBarOpenMobile: (open: boolean) => void;
}

export const SideBarContext = createContext<SideBarContextProps>(
  {} as SideBarContextProps
);
export const useSideBarContext = () => useContext(SideBarContext);
