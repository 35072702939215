import React from 'react';
import { Auth0Provider } from '../hooks/auth0';

const domain = process.env.REACT_APP_AUTH0_DOMAIN || '';
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID || '';
const audience = process.env.REACT_APP_AUTH0_AUDIENCE || '';

const withAuth0ProviderHOC = <P extends object>(
  Component: React.ComponentType<P>
) => {
  return (props: any) => {
    return (
      <Auth0Provider
        domain={domain}
        client_id={clientId}
        audience={audience}
        redirect_uri={window.location.origin}>
        <Component {...(props as P)} />
      </Auth0Provider>
    );
  };
};

// for testing
const NoAuth0 = <P extends object>(Component: React.ComponentType<P>) => {
  return (props: any) => <Component {...(props as P)} />;
};

const withAuth0Provider = process.env.JEST_WORKER_ID
  ? NoAuth0
  : withAuth0ProviderHOC;

export { withAuth0Provider };
