import React, { useState, useContext, createContext } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

interface ErrorDialogProps {
  showErrorDialog: (value: ((prevState: boolean) => boolean) | boolean) => void;
  setErrorDialogTitle: (
    value: ((prevState: string) => string) | string
  ) => void;
  setErrorDialogContent: (
    value: ((prevState: string) => string) | string
  ) => void;
}

export const ErrorDialogContext = createContext<ErrorDialogProps | null>(null);
export const useErrorDialog = () => useContext(ErrorDialogContext);

export const ErrorDialogProvider: React.FC = ({ children }) => {
  const [showError, setShowError] = useState(false);
  const [title, setTitle] = useState('Something went wrong');
  const [content, setContent] = useState(
    'There might be some issue occurred. Please try again or refresh the page later.'
  );

  return (
    <ErrorDialogContext.Provider
      value={{
        showErrorDialog: setShowError,
        setErrorDialogTitle: setTitle,
        setErrorDialogContent: setContent,
      }}>
      <Dialog
        open={showError}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowError(false)}>OK</Button>
        </DialogActions>
      </Dialog>
      {children}
    </ErrorDialogContext.Provider>
  );
};
