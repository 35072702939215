import React from 'react';
import {
  createStyles,
  makeStyles,
  lighten,
  Theme,
} from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import { Button } from '@material-ui/core';
import SaveAltIcon from '@material-ui/core/SvgIcon/SvgIcon';
import InputIcon from '@material-ui/icons/Input';
import { Rule } from '../../../graphql/types';
import styled from 'styled-components';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolBar: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    highlight: {
      color: theme.palette.text.primary,
      backgroundColor: lighten(theme.palette.info.dark, 0.5),
    },
    title: {
      flex: '1 1 100%',
    },
    importExportButton: {
      width: '20em',
    },
  })
);

const ExportButton = styled(Button)`
  margin-left: 10px;
  width: 30em;
`;

interface RulesToolBarProps {
  selectedRules: Rule[];
  handleExportRule: () => void;
  handleExportRuleToCsv: () => void;
  handleExportLendiCodeToCsv: () => void;
  handleImportRuleClicked: () => void;
  ruleTableName?: string;
}

const RulesToolBar: React.FC<RulesToolBarProps> = ({
  selectedRules,
  handleExportRule,
  handleExportRuleToCsv,
  handleExportLendiCodeToCsv,
  handleImportRuleClicked,
  ruleTableName,
}) => {
  const classes = useStyles();
  return (
    <Toolbar
      className={clsx(classes.toolBar, {
        [classes.highlight]: selectedRules.length > 0,
      })}>
      {selectedRules.length > 0 ? (
        <>
          <Typography
            className={classes.title}
            color={'inherit'}
            variant="subtitle1"
            component="div">
            {selectedRules.length} selected
          </Typography>
          <ExportButton
            color="primary"
            onClick={() => handleExportLendiCodeToCsv()}
            disableTouchRipple={true}
            size={'small'}
            fullWidth={false}
            endIcon={<SaveAltIcon />}
            variant={'contained'}
            disabled={selectedRules.length === 0}>
            Export Lendi Code to CSV
          </ExportButton>
          <ExportButton
            color="primary"
            onClick={() => handleExportRuleToCsv()}
            disableTouchRipple={true}
            size={'small'}
            fullWidth={false}
            endIcon={<SaveAltIcon />}
            variant={'contained'}
            disabled={selectedRules.length === 0}>
            Export Rules to CSV
          </ExportButton>
          <ExportButton
            color="primary"
            onClick={() => handleExportRule()}
            disableTouchRipple={true}
            size={'small'}
            fullWidth={false}
            endIcon={<SaveAltIcon />}
            variant={'contained'}
            disabled={selectedRules.length === 0}>
            Export Rules to JSON
          </ExportButton>
        </>
      ) : (
        <>
          <Typography
            className={classes.title}
            variant="h6"
            id="tableTitle"
            component="div">
            {ruleTableName || 'Rules'}
          </Typography>
          <Button
            className={classes.importExportButton}
            color="default"
            onClick={() => handleImportRuleClicked()}
            disableTouchRipple={true}
            size={'small'}
            fullWidth={false}
            endIcon={<InputIcon />}
            variant={'contained'}>
            Import from JSON
          </Button>
        </>
      )}
    </Toolbar>
  );
};

export default RulesToolBar;
