import React, { useState, useEffect } from 'react';
import { DefaultMitigation, Action } from '../../graphql/types';
import styled from 'styled-components';
import ActionSelect from './ActionSelect';
import TextField from '@material-ui/core/TextField';
import RuleCheckbox from './RuleCheckbox';
import DocTypeMultiSelect from '../DocTypeMultiSelect';
import Alert from '@material-ui/lab/Alert';
import { validateNonEmptyString } from '../utils/validators';
import useDidMountEffect from '../../hooks/did-mount-effect';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 4;
`;

interface DefaultMitigationProps {
  currentDefaultMitigation: DefaultMitigation;
  onDefaultMitigationChange: (mitigation: DefaultMitigation) => void;
}

const DefaultMitigationPolicy: React.FC<DefaultMitigationProps> = ({
  currentDefaultMitigation,
  onDefaultMitigationChange,
}) => {
  const [defaultMitigation, setDefaultMitigation] = useState({
    ...currentDefaultMitigation,
  });
  const [actionError, setActionError] = useState(false);
  const handleActionChange = (action: Action) => {
    if (action === Action.ActionRequired) {
      setDefaultMitigation({
        ...defaultMitigation,
        action,
        mitigationRequirement: {
          allowOverride: true,
          hardstop: false,
          commentRequired: false,
          requiredDocTypes: [],
        },
      });
    } else {
      setDefaultMitigation({
        ...defaultMitigation,
        action,
        mitigationRequirement: null,
      });
    }
  };

  const handleDescriptionChange = (description: string) => {
    defaultMitigation.description = description;
    setDefaultMitigation({ ...defaultMitigation });
  };

  const handleHardstopChange = (hardstop: boolean) => {
    defaultMitigation.mitigationRequirement!.hardstop = hardstop;
    setDefaultMitigation({ ...defaultMitigation });
  };

  const handleOverrideChange = (override: boolean) => {
    defaultMitigation.mitigationRequirement!.allowOverride = override;
    setDefaultMitigation({ ...defaultMitigation });
  };

  const handleRequireCommentChange = (commentRequired: boolean) => {
    defaultMitigation.mitigationRequirement!.commentRequired = commentRequired;
    setDefaultMitigation({ ...defaultMitigation });
  };

  const handleDocTypeChange = (docTypes: string[]) => {
    defaultMitigation.mitigationRequirement!.requiredDocTypes = docTypes;
    setDefaultMitigation({ ...defaultMitigation });
  };

  useDidMountEffect(() => {
    if (validateNonEmptyString(defaultMitigation.action)) {
      setActionError(false);
    } else {
      setActionError(true);
    }
  }, [defaultMitigation.action]);

  useEffect(() => {
    onDefaultMitigationChange(defaultMitigation);
  }, [onDefaultMitigationChange, defaultMitigation]);

  return (
    <Container>
      <ActionSelect
        currentSelected={currentDefaultMitigation.action}
        onActionSelected={action => handleActionChange(action as Action)}
      />
      {actionError && (
        <Alert severity="warning">Default mitigation action required!</Alert>
      )}
      <TextField
        label="Mitigation description"
        multiline
        minRows={2}
        maxRows={2}
        fullWidth
        defaultValue={currentDefaultMitigation.description}
        onChange={event => handleDescriptionChange(event.target.value)}
      />
      {defaultMitigation.action === Action.ActionRequired && (
        <div>
          <RuleCheckbox
            description="Policy can be overridden"
            label={'Overridden'}
            currentValue={
              defaultMitigation.mitigationRequirement!.allowOverride
            }
            onCheckChange={checked => handleOverrideChange(checked)}
          />
          <RuleCheckbox
            description="Can not be mitigated"
            label={'Hardstop'}
            currentValue={defaultMitigation.mitigationRequirement!.hardstop}
            onCheckChange={checked => handleHardstopChange(checked)}
          />
          <RuleCheckbox
            description="Comment required"
            label={'Requires comment'}
            currentValue={
              defaultMitigation.mitigationRequirement!.commentRequired
            }
            onCheckChange={checked => handleRequireCommentChange(checked)}
          />
          <DocTypeMultiSelect
            onSelectedDocTypes={docTypes => handleDocTypeChange(docTypes)}
            selectedDocTypeUUIDs={
              defaultMitigation.mitigationRequirement!.requiredDocTypes
            }
          />
        </div>
      )}
    </Container>
  );
};

export default DefaultMitigationPolicy;
