export type RuleOperationResult = {
  ruleId: string;
  ruleName: string;
  operationResult: OperationResult;
  errorMessage?: string;
};

export enum OperationResult {
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
}
