import React from 'react';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { CssBaseline } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
  palette: {
    type: 'dark',
  },
});

export const withThemeProvider = <P extends object>(
  Component: React.ComponentType<P>
) => {
  return (props: any) => {
    return (
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <Component {...(props as P)} />
      </MuiThemeProvider>
    );
  };
};
