import * as monaco from 'monaco-editor';
import factSchemas, { findEnumSuggestion, FactSchema } from './fact-schemas';

export const droolLanguage = 'drl';

const factLevelCompletionItems = (range: any) => {
  return factSchemas.map(factSchema => {
    // eslint-disable-next-line
    const insertedText = factSchema.name + '(${0})';
    const label = factSchema.name;
    return {
      label: label,
      kind: monaco.languages.CompletionItemKind.Field,
      insertText: insertedText,
      insertTextRules:
        monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
      range: range,
    };
  });
};

const propertyLevelCompletionItems = (range: any, properties: any[]) => {
  return properties.map(property => {
    const label = property.name;
    return {
      label: label,
      kind: monaco.languages.CompletionItemKind.Property,
      // eslint-disable-next-line
      insertText: property.name + ' == ${0}',
      insertTextRules:
        monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
      range: range,
    };
  });
};

const enumCompletionItem = (range: any, enumValue: string) => {
  return {
    label: enumValue,
    kind: monaco.languages.CompletionItemKind.Enum,
    insertText: enumValue,
    insertTextRules:
      monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
    range: range,
  };
};

monaco.languages.register({ id: droolLanguage });

// Register fact level completion items
monaco.languages.registerCompletionItemProvider(droolLanguage, {
  provideCompletionItems: (model, position) => {
    const textUntilPosition = model.getValueInRange({
      startLineNumber: 1,
      startColumn: 1,
      endLineNumber: position.lineNumber,
      endColumn: position.column,
    });
    const match = textUntilPosition.match(new RegExp('[^\\s]+\\([^\\)]*$'));
    if (match) {
      return { suggestions: [] };
    }
    const maxRange = {
      startLineNumber: position.lineNumber,
      endLineNumber: position.lineNumber,
      startColumn: position.column - 1,
      endColumn: model.getLineMaxColumn(position.lineNumber),
    };
    return {
      suggestions: factLevelCompletionItems(maxRange),
    };
  },
});

// Register property level completion items
monaco.languages.registerCompletionItemProvider(droolLanguage, {
  provideCompletionItems: function(model, position) {
    const textUntilPosition = model.getValueInRange({
      startLineNumber: 1,
      startColumn: 1,
      endLineNumber: position.lineNumber,
      endColumn: position.column,
    });
    const lastFactName: string = getLastFactName(textUntilPosition) || '';
    const matchedFact: FactSchema | undefined = factSchemas.find(
      factSchemas => factSchemas.name === lastFactName
    );

    const word = model.getWordUntilPosition(position);
    const range = {
      startLineNumber: position.lineNumber,
      endLineNumber: position.lineNumber,
      startColumn: word.startColumn,
      endColumn: word.endColumn,
    };
    return {
      suggestions: propertyLevelCompletionItems(
        range,
        matchedFact?.properties || []
      ),
    };
  },
});

// Register enum register item
monaco.languages.registerCompletionItemProvider(droolLanguage, {
  provideCompletionItems: function(model, position) {
    const textUntilPosition: string = model.getValueInRange({
      startLineNumber: 1,
      startColumn: 1,
      endLineNumber: position.lineNumber,
      endColumn: position.column,
    });
    const lastFactName: string | undefined = getLastFactName(textUntilPosition);
    const lastPropertyName: string | undefined = getLastPropertyName(
      textUntilPosition
    );
    if (lastFactName && lastPropertyName) {
      const word = model.getWordUntilPosition(position);
      const range = {
        startLineNumber: position.lineNumber,
        endLineNumber: position.lineNumber,
        startColumn: word.startColumn,
        endColumn: word.endColumn,
      };
      return {
        suggestions: findEnumSuggestion(
          lastFactName,
          lastPropertyName
        ).map(enumValue => enumCompletionItem(range, enumValue)),
      };
    } else {
      return {
        suggestions: [],
      };
    }
  },
});

const getLastMatch = (
  iterator: IterableIterator<RegExpMatchArray>
): RegExpMatchArray | undefined => {
  let lastMatch: RegExpMatchArray | undefined;
  let item: IteratorResult<RegExpMatchArray> = iterator.next();
  while (!item.done) {
    lastMatch = item.value;
    item = iterator.next();
  }
  return lastMatch;
};

const getLastFactName = (text: string): string | undefined => {
  const factMatchingGroup: string = factSchemas
    .map(schema => schema.name)
    .join('|');
  // one of the facts' name, following with or without space, then a (
  const pattern: string = `(${factMatchingGroup})\\s*\\(`;
  const lastMatch: RegExpMatchArray | undefined = getLastMatch(
    text.matchAll(new RegExp(pattern, 'g'))
  );
  if (lastMatch && lastMatch.length === 2) {
    return lastMatch[1];
  }
};

const getLastPropertyName = (text: string): string | undefined => {
  // propertyName, assumption here it's all alphabetical mixed with number and '_' or '-', following by
  // == preceded with at least one space OR in preceded with at least one space
  // this works with keyword `in` and `contains`
  const lastMatch: RegExpMatchArray | undefined = getLastMatch(
    text.matchAll(/([a-zA-Z0-9_-]+)(\s+==|\s+in|\s+contains)/g)
  );
  if (lastMatch && lastMatch.length === 3) {
    return lastMatch[1];
  }
};
