import React from 'react';
import { Redirect } from 'react-router-dom';
import { useAuth0 } from '../hooks/auth0';
import FingerprintIcon from '@material-ui/icons/Fingerprint';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Loading from '../components/Loading';

const useStyles = makeStyles({
  loadingMessage: {
    display: 'flex',
    'flex-direction': 'row',
    'align-items': 'center',
    'justify-content': 'center',
  },
});

const withAuth0HOC = <P extends object>(Component: React.ComponentType<P>) => {
  return (props: any) => {
    const classes = useStyles();
    const auth0 = useAuth0();

    if (auth0?.loading === true) {
      return (
        <Loading>
          <Typography className={classes.loadingMessage}>
            <FingerprintIcon /> Authenticating...
          </Typography>
        </Loading>
      );
    }

    if (auth0?.isAuthenticated === false && auth0?.loading === false) {
      if (window.location.search.includes('error=')) {
        return <Redirect to="/unauthorized" />;
      }

      return <Redirect to="/sign-in" />;
    }

    return <Component {...(props as P)} />;
  };
};

const NoAuth = <P extends object>(Component: React.ComponentType<P>) => {
  return (props: any) => <Component {...(props as P)} />;
};

const withAuth = process.env.JEST_WORKER_ID ? NoAuth : withAuth0HOC;

export { withAuth };
