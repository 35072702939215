import React, { useState } from 'react';
import AppBar from '@material-ui/core/AppBar';
import clsx from 'clsx';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import { useSideBarContext } from './Context';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router';
import { Alert } from '@material-ui/lab';
import Collapse from '@material-ui/core/Collapse';
import { alpha } from '@material-ui/core/styles';

const drawerWidth = 240;
const appBarHeight = 64;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      [theme.breakpoints.up('sm')]: {
        transition: theme.transitions.create(['margin', 'width'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth,
      },
    },
    rootShift: {
      [theme.breakpoints.up('sm')]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: 0,
        transition: theme.transitions.create(['margin', 'width'], {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
    },
    appBar: {
      [theme.breakpoints.up('sm')]: {
        transition: theme.transitions.create(['margin', 'width'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
      },
    },
    appBarShift: {
      [theme.breakpoints.up('sm')]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
    },
    container: {
      display: 'flex',
      'flex-direction': 'column',
      'align-items': 'flex-start',
    },
    banner: {
      'margin-top': `${appBarHeight}px`,
      height: '2.1875rem',
    },
    content: {
      width: '100%',
    },
    envIndicator: {
      width: '100%',
      position: 'fixed',
      zIndex: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
      display: 'none',
      [theme.breakpoints.up('sm')]: {
        display: 'block',
      },
    },
    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: alpha(theme.palette.common.white, 0.15),
      '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
      },
      marginLeft: 0,
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
      },
    },
    searchButton: {
      width: '100%',
    },
    inputRoot: {
      color: 'inherit',
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 7),
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: 120,
        '&:focus': {
          width: 200,
        },
      },
    },
  })
);

const SearchAppBar: React.FC = ({ children }) => {
  const classes = useStyles();
  const {
    sideBarOpen,
    setSideBarOpen,
    sideBarOpenMobile,
    setSideBarOpenMobile,
  } = useSideBarContext();

  const history = useHistory();
  const handleOpenSearchDialog = () => {
    history.push('/', { searchDialog: true });
  };
  // todo: confirm with BA what to do with production env? atm production has warning status top bar.
  const [showEnv] = useState(true);

  return (
    <div
      className={clsx(classes.root, {
        [classes.rootShift]: sideBarOpen,
      })}>
      <AppBar
        position="fixed"
        className={clsx(classes.root, {
          [classes.rootShift]: sideBarOpen,
        })}>
        <Toolbar>
          <Hidden smUp implementation="css">
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              onClick={() => {
                setSideBarOpenMobile(!sideBarOpenMobile);
              }}
              aria-label="open drawer">
              <MenuIcon />
            </IconButton>
          </Hidden>
          <Hidden xsDown implementation="css">
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              onClick={() => {
                setSideBarOpen(!sideBarOpen);
              }}
              aria-label="open drawer">
              <MenuIcon />
            </IconButton>
          </Hidden>
          <Typography className={classes.title} variant="h6" noWrap>
            Rules Authoring Tool
          </Typography>
          <div className={classes.search}>
            <Button
              className={classes.searchButton}
              onClick={() => handleOpenSearchDialog()}
              disableTouchRipple={true}
              endIcon={<SearchIcon />}
              size={'medium'}
              variant={'outlined'}>
              Search filters
            </Button>
          </div>
        </Toolbar>
      </AppBar>
      <div className={classes.container}>
        <div className={classes.banner}>
          <Collapse in={showEnv}>
            <Alert
              className={classes.envIndicator}
              severity={
                process.env.REACT_APP_ENV === 'development'
                  ? 'info'
                  : process.env.REACT_APP_ENV === 'staging'
                  ? 'success'
                  : 'warning'
              }>
              You're using rule authoring tool in {process.env.REACT_APP_ENV}
            </Alert>
          </Collapse>
        </div>
        <main className={classes.content}>{children}</main>
      </div>
    </div>
  );
};

export default SearchAppBar;
