import React from 'react';
import styled from 'styled-components';
import Backdrop from '@material-ui/core/Backdrop';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const UnauthorizedScreen: React.FC = () => {
  const history = useHistory();
  return (
    <Container>
      <Backdrop open={true}>
        <Container>
          <Typography>Unauthorized access is not allowed.</Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => history.replace('/')}>
            Retry
          </Button>
        </Container>
      </Backdrop>
    </Container>
  );
};

export default UnauthorizedScreen;
