import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import LendiCodeAndRatingSelect from './LendiCodeAndRatingSelect';
import LenderMultiSelect from '../LenderMultiSelect';
import { Alert } from '@material-ui/lab';
import { FieldErrors } from 'react-hook-form/dist/types/errors';

interface LendiCodeTableItemProps {
  lendiRating: {
    lenders: string[];
    rating: string;
  };
  index: number;
}

const isLenderCodeFieldContainsError = (
  errors: FieldErrors<any>,
  index: number
) =>
  errors.lendiCodeRating &&
  errors.lendiCodeRating!.ratings &&
  errors.lendiCodeRating!.ratings[index] &&
  errors.lendiCodeRating!.ratings[index].lenders;

const LendiCodeTableItem: React.FC<LendiCodeTableItemProps> = ({
  lendiRating,
  index,
}) => {
  const {
    setValue,
    register,
    formState: { errors },
    clearErrors,
  } = useFormContext();

  useEffect(() => {
    register(`lendiCodeRating.ratings.${index}.lenders`, { required: true });
    register(`lendiCodeRating.ratings.${index}.rating`, { required: true });
  });

  return (
    <React.Fragment>
      <LenderMultiSelect
        label="Lender for Lendi Code"
        selectedLenderUUIDs={lendiRating.lenders}
        onSelectedLenders={lenders => {
          clearErrors(`lendiCodeRating.ratings.${index}.lenders`);
          setValue(`lendiCodeRating.ratings.${index}.lenders`, lenders);
        }}
      />
      {isLenderCodeFieldContainsError(errors, index) && (
        <Alert severity="warning">LendiCode lender(s) required!</Alert>
      )}
      <LendiCodeAndRatingSelect
        rating={lendiRating.rating}
        fieldName={`lendiCodeRating.ratings.${index}.rating`}
      />
    </React.Fragment>
  );
};

export default LendiCodeTableItem;
