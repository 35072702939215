import React, { useState } from 'react';
import styled from 'styled-components';
import AppBar from './AppBar';
import SideBar from './SideBar';
import AnimatedSwitch from '../AnimatedSwitch';
import { SideBarContext } from './Context';

const Container = styled.div`
  display: flex;
  height: 100vh;
  flex-direction: row;
  flex-wrap: wrap;
`;

const DefaultLayout: React.FC = ({ children }) => {
  const [sideBarOpen, setSideBarOpen] = useState(true);
  const [sideBarOpenMobile, setSideBarOpenMobile] = useState(false);
  return (
    <SideBarContext.Provider
      value={{
        sideBarOpen,
        setSideBarOpen,
        sideBarOpenMobile,
        setSideBarOpenMobile,
      }}>
      <Container>
        <SideBar />
        <AppBar>
          <AnimatedSwitch>{children}</AnimatedSwitch>
        </AppBar>
      </Container>
    </SideBarContext.Provider>
  );
};

export default DefaultLayout;
