import React from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Portal from '@material-ui/core/Portal';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    'min-width': '100vw',
    'min-height': '100vh',
    'align-items': 'center',
    'justify-content': 'center',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#eee',
  },
  loading: {
    display: 'flex',
    'flex-direction': 'column',
    'align-items': 'center',
    'justify-content': 'center',
  },
}));

const Loading: React.FC = ({ children }) => {
  const classes = useStyles();
  return (
    <Portal>
      <div className={classes.root}>
        <Backdrop className={classes.backdrop} open={true}>
          <div className={classes.loading}>
            {children}
            <CircularProgress color="inherit" />
          </div>
        </Backdrop>
      </div>
    </Portal>
  );
};

export default Loading;
