import React, { useEffect, useState } from 'react';
import AddBoxIcon from '@material-ui/icons/AddBox';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import LendiCodeTableItem from './LendiCodeTableItem';
import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import LendiCodeAndRatingSelect from './LendiCodeAndRatingSelect';
import { Autocomplete } from '@material-ui/lab';
import {
  LendiCodeRating,
  useListLendicodesQuery,
  LendiCode,
} from '../../graphql/types';
import Alert from '@material-ui/lab/Alert';
import Loading from '../Loading';
import { useErrorDialog } from '../../hooks/error-dialog';

const QUERY_LIMIT = 400;

const useStyles = makeStyles({
  root: {
    margin: '20px 0px',
  },
  tableHeader: {
    display: 'flex',
    'align-items': 'center',
  },
  flat: {
    display: 'flex',
    'align-items': 'center',
  },
});

const LendiCodeContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  width: 100%;
`;

const LendiCodeTitle = styled.div`
  margin: 10px 0px;
  font-size: 1em;
  flex-wrap: wrap;
`;

const LendiCodeContent = styled.div`
  display: flex;
  justify-content: space-between;
`;

const defaultLendiRating = {
  lenders: [],
  rating: '',
};

const LendiCodeTable: React.FC<LendiCodeRating> = ({
  lendiCode,
  ratings,
  defaultRating,
}) => {
  const classes = useStyles();
  const {
    register,
    formState: { errors },
    clearErrors,
    getValues,
  } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    name: 'lendiCodeRating.ratings',
  });

  const newLendiRating = () => {
    append(defaultLendiRating);
  };

  const removeLendiRating = (index: number) => {
    remove(index);
  };

  const renderRow = (rating: any, index: number) => {
    return (
      <TableRow key={`TableRow-${index}`}>
        <TableCell>
          <div className={classes.flat}>
            rating {index + 1}
            <IconButton
              aria-label="settings"
              onClick={() => removeLendiRating(index)}>
              <DeleteForeverIcon />
            </IconButton>
          </div>
        </TableCell>
        <TableCell>
          <LendiCodeTableItem lendiRating={rating} index={index} />
        </TableCell>
      </TableRow>
    );
  };

  const result = useListLendicodesQuery({
    variables: {
      limit: QUERY_LIMIT,
    },
  });

  const errorDialog = useErrorDialog();
  const { loading, error } = result;
  const [lendiCodes, setLendiCodes] = useState([] as LendiCode[]);

  useEffect(() => {
    register('lendiCodeRating.lendiCode', { required: false });
    register('lendiCodeRating.defaultRating', {
      required: true,
      validate: {
        isLendiDefaultRatingConfiguredWithoutLendiCode: defaultRating => {
          if (defaultRating && defaultRating !== 'Off') {
            const lendiCode = getValues('lendiCodeRating.lendiCode');
            return lendiCode !== null && lendiCode !== '';
          }
          return true;
        },
      },
    });
  });

  useEffect(() => {
    const lendiCodes = result.data?.listLendiCodes.lendiCodes || [];
    const lendiCodesCopy = [...lendiCodes];
    lendiCodesCopy.sort();
    setLendiCodes(lendiCodesCopy);
  }, [result]);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    errorDialog?.showErrorDialog(true);
    return <div />;
  }

  return (
    <React.Fragment>
      <Controller
        name="lendiCodeRating.lendiCode"
        defaultValue={lendiCode}
        render={({ field }) => (
          <Autocomplete
            {...field}
            options={lendiCodes.map(data => data.lendiCode)}
            getOptionLabel={option => option}
            renderInput={params => <TextField {...params} label="Lendi Code" />}
            onChange={(_, data) => {
              clearErrors('defaultMitigation');
              field.onChange(data);
            }}
          />
        )}
      />
      {errors.defaultMitigation &&
        errors.defaultMitigation.type ===
          'isRuleMitigationPolicyOrLendiCodeConfigured' && (
          <Alert severity="warning">Lendicode should be set</Alert>
        )}

      {errors.lendiCodeRating &&
        errors.lendiCodeRating.defaultRating &&
        errors.lendiCodeRating.defaultRating.type ===
          'isLendiDefaultRatingConfiguredWithoutLendiCode' && (
          <Alert severity="warning">
            Lendicode should be set, when defaultRating is configured
          </Alert>
        )}

      <TableContainer className={classes.root}>
        <div className={classes.tableHeader}>
          <Typography>Rating</Typography>
          <IconButton aria-label="settings" onClick={newLendiRating}>
            <AddBoxIcon />
          </IconButton>
        </div>
        <Table>
          <TableBody>
            {fields.map((rating, index) => renderRow(rating, index))}
          </TableBody>
        </Table>
      </TableContainer>
      <LendiCodeContainer>
        <LendiCodeContent>
          <Grid container spacing={1}>
            <Grid item xs>
              <LendiCodeTitle>
                <Typography>Default Lendi Code Rating</Typography>
              </LendiCodeTitle>
            </Grid>
            <Grid item xs>
              <LendiCodeAndRatingSelect
                rating={defaultRating.toString()}
                fieldName={'lendiCodeRating.defaultRating'}
              />
            </Grid>
          </Grid>
        </LendiCodeContent>
      </LendiCodeContainer>
    </React.Fragment>
  );
};

const lendiCodeTableRender = (lendiCodeRating: LendiCodeRating) => (
  <LendiCodeTable {...lendiCodeRating} />
);
export { LendiCodeTable as default, lendiCodeTableRender };
