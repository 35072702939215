import React from 'react';
import { LenderDataProvider } from '../hooks/lenders';

const url = process.env.REACT_APP_FUNDERS_API_URL || '';

const withLenderDataProvider = <P extends object>(
  Component: React.ComponentType<P>
) => {
  return (props: any) => {
    return (
      <LenderDataProvider url={url}>
        <Component {...(props as P)} />
      </LenderDataProvider>
    );
  };
};

export { withLenderDataProvider };
