import React, { useState, useEffect, useRef, useMemo } from 'react';
import { TableCell, Grid, Snackbar } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import makeStyles from '@material-ui/core/styles/makeStyles';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import { createStyles } from '@material-ui/core/styles';
import {
  useListLendicodesQuery,
  LendiCode,
  useNewLendiCodeMutation,
  useUpdateLendiCodeMutation,
} from '../../../graphql/types';
import LendiCodeDialog from './LendiCodeDialog';
import TableLoading from '../../Shared/TableLoading';
import { useErrorDialog } from '../../../hooks/error-dialog';
import Checkbox from '@material-ui/core/Checkbox';
import * as FileSaver from 'file-saver';
import ImportExportToolBar from '../../Shared/ImportExportToolBar';
import { Alert } from '@material-ui/lab';
import { convertJson2CsvContent } from '../../../services';

const QUERY_LIMIT = 400;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 36px;
  width: 100%;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px 0px 20px 0px;
  width: 100%;
`;

const defaultNewLendiCode = {
  lendiCode: '',
  description: '',
};

const useStyles = makeStyles(theme =>
  createStyles({
    table: {
      minWidth: 650,
    },
    button: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    export: {
      display: 'flex',
      'align-items': 'center',
    },
    root: {
      width: '100%',
    },
    fileInput: {
      display: 'none',
    },
  })
);

enum OperationResult {
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
}

type LendiCodeOperationResult = {
  code: string;
  operationResult: OperationResult;
  errorMessage?: string;
};

const LendiCodeManagementScreen: React.FC = () => {
  const classes = useStyles();
  const queryResult = useListLendicodesQuery({
    variables: {
      limit: QUERY_LIMIT,
    },
  });

  const {
    refetch: refetchLendiCodes,
    loading: lendiCodeQueryLoading,
    error: lendiCodeQueryError,
  } = queryResult;
  const [lendiCodes, setLendiCodes] = useState([] as LendiCode[]);
  const [selectedLendiCodes, setSelectedLendiCodes] = useState(
    [] as LendiCode[]
  );
  const hasSelectedLendiCodes = useMemo<boolean>(
    () => selectedLendiCodes.length > 0,
    [selectedLendiCodes]
  );
  const [
    lendiCodeOperationInProgress,
    setLendiCodeOperationInProgress,
  ] = useState(false);
  const [
    lendiCodeOperationCompleted,
    setLendiCodeOperationCompleted,
  ] = useState(false);
  const [lendiCodeImportResult, setlendiCodeImportResult] = useState(
    [] as LendiCodeOperationResult[]
  );

  const errorDialog = useErrorDialog();
  // status control
  const [isError, setError] = useState(false);
  const [isLoading, setLoading] = useState(false);

  // dialog
  const [open, setOpen] = useState(false);
  const [newLendiCodeDialogOpen, setNewLendiCodeDialogOpen] = useState(false);
  const [currentLendiCode, setCurrentLendiCode] = useState({} as LendiCode);

  const [
    newLendiCode,
    { loading: newLendiCodeMutationLoading, error: newLendiCodeMutationError },
  ] = useNewLendiCodeMutation();
  const [
    updateLendiCode,
    {
      loading: updateLendiCodeMutationLoading,
      error: updateLendiCodeMutationError,
    },
  ] = useUpdateLendiCodeMutation();

  const save = (data: LendiCode) => {
    newLendiCode({
      variables: {
        lendiCode: {
          lendiCode: data.lendiCode,
          description: data.description,
        },
      },
    }).then(() => refetchLendiCodes());
  };

  const update = (data: LendiCode) => {
    updateLendiCode({
      variables: {
        lendiCode: {
          lendiCode: data.lendiCode,
          description: data.description || '',
        },
      },
    }).then(() => refetchLendiCodes());
  };

  const handleToggleSelectCode = (data: LendiCode) => {
    if (selectedLendiCodes.includes(data)) {
      setSelectedLendiCodes(
        selectedLendiCodes.filter(c => c.lendiCode !== data.lendiCode)
      );
    } else {
      setSelectedLendiCodes([...selectedLendiCodes, data]);
    }
  };

  const handleUnselectAll = () => {
    setSelectedLendiCodes([]);
  };

  const handleSelectAll = () => {
    setSelectedLendiCodes(lendiCodes);
  };

  const handleExportLendiCode = () => {
    const blob = new Blob([JSON.stringify(selectedLendiCodes, null, 1)], {
      type: 'application/json;charset=utf-8',
    });
    FileSaver.saveAs(
      blob,
      `${process.env.REACT_APP_ENV}-lendi-code-${new Date().toISOString()}.json`
    );
  };

  const handleImportLendiCode = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setLendiCodeOperationInProgress(true);
    setLendiCodeOperationCompleted(false);
    const file = event.target.files!.item(0);
    if (file !== null) {
      const fileReader = new FileReader();
      fileReader.onload = function(e) {
        const raw = fileReader.result;
        const json = JSON.parse(raw!.toString());
        const lendiCodesToImport = json as LendiCode[];
        const executedCodes = [] as LendiCodeOperationResult[];
        const recordSuccessResult = (lendiCode: string) =>
          executedCodes.push({
            code: lendiCode,
            operationResult: OperationResult.SUCCESS,
            errorMessage: '',
          });
        const recordFailureResult = (lendiCode: string, errorMessage: string) =>
          executedCodes.push({
            code: lendiCode,
            operationResult: OperationResult.FAILED,
            errorMessage,
          });

        const importOneLendiCode = (toImport: LendiCode): Promise<any> => {
          const matchedExistingCode = lendiCodes.find(
            code => code.lendiCode === toImport.lendiCode
          );
          const codeExists = matchedExistingCode !== undefined;
          if (codeExists) {
            return updateLendiCode({
              variables: {
                lendiCode: {
                  lendiCode: toImport.lendiCode,
                  description: toImport.description || '',
                },
              },
            })
              .then(_ => recordSuccessResult(toImport.lendiCode))
              .catch(err => {
                recordFailureResult(toImport.lendiCode, err.message);
                errorDialog?.showErrorDialog(true);
              });
          } else {
            return newLendiCode({
              variables: {
                lendiCode: {
                  lendiCode: toImport.lendiCode,
                  description: toImport.description,
                },
              },
            })
              .then(_ => recordSuccessResult(toImport.lendiCode))
              .catch(err => {
                recordFailureResult(toImport.lendiCode, err.message);
                errorDialog?.showErrorDialog(true);
              });
          }
        };

        const promises: Promise<any>[] = lendiCodesToImport.map(toImport =>
          importOneLendiCode(toImport)
        );

        Promise.allSettled(promises).then(_ => {
          if (
            executedCodes.length === lendiCodesToImport.length &&
            executedCodes.length !== 0
          ) {
            setLendiCodeOperationInProgress(false);
            setlendiCodeImportResult(executedCodes);
            setLendiCodeOperationCompleted(true);
          }
          refetchLendiCodes().catch(_ => {
            errorDialog?.showErrorDialog(true);
          });
        });
      };
      fileReader.readAsText(file, 'application/json;charset=utf-8');
    }
  };

  useEffect(() => {
    setLoading(
      updateLendiCodeMutationLoading ||
        newLendiCodeMutationLoading ||
        lendiCodeQueryLoading
    );
  }, [
    updateLendiCodeMutationLoading,
    newLendiCodeMutationLoading,
    lendiCodeQueryLoading,
  ]);

  useEffect(() => {
    setError(
      updateLendiCodeMutationError !== undefined ||
        newLendiCodeMutationError !== undefined ||
        lendiCodeQueryError !== undefined
    );
  }, [
    updateLendiCodeMutationError,
    newLendiCodeMutationError,
    lendiCodeQueryError,
  ]);

  const inputEl = useRef<HTMLInputElement | null>(null);
  const handleImportLendiCodeClicked = () => {
    inputEl.current!.click();
  };

  const LendiCodeTable = () => (
    <Paper className={classes.root} elevation={3}>
      <ImportExportToolBar
        selectedRows={selectedLendiCodes}
        shouldDisplayExportButtons={hasSelectedLendiCodes}
        shouldDisplayImportButtons={!hasSelectedLendiCodes}
        handleExportToJson={handleExportLendiCode}
        handleExportToCsv={() => {}}
        handleImportClicked={handleImportLendiCodeClicked}
        shouldHighlightToolBar={hasSelectedLendiCodes}
        shouldDisplayNumberOfItemsSelected={true}
        tableName="Lendi Code"
      />
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={lendiCodeOperationInProgress}
        autoHideDuration={500}>
        <Alert severity="info">Lendi code operation in progress!</Alert>
      </Snackbar>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell size={'small'}>
                <div className={classes.export}>
                  <Checkbox
                    checked={selectedLendiCodes.length !== 0}
                    onChange={() =>
                      selectedLendiCodes.length === 0
                        ? handleSelectAll()
                        : handleUnselectAll()
                    }
                    color="primary"
                    indeterminate={
                      selectedLendiCodes.length > 0 &&
                      selectedLendiCodes.length !== lendiCodes.length
                    }
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                </div>
              </TableCell>
              <TableCell>Lendi Code</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {lendiCodes.map(row => (
              <TableRow key={row.lendiCode}>
                <TableCell>
                  <Checkbox
                    checked={selectedLendiCodes.includes(row)}
                    onChange={() => handleToggleSelectCode(row)}
                    color="primary"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                </TableCell>
                <TableCell component="th" scope="row">
                  {row.lendiCode}
                </TableCell>
                <TableCell>{row.description}</TableCell>
                <TableCell>
                  <div className={classes.button}>
                    <Button
                      variant="contained"
                      color={'primary'}
                      onClick={() => {
                        setOpen(true);
                        setCurrentLendiCode(row);
                      }}>
                      Edit
                    </Button>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <LendiCodeDialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        onSave={(data: LendiCode) => {
          update(data);
          setOpen(false);
        }}
        data={currentLendiCode}
      />
      <input
        type="file"
        className={classes.fileInput}
        ref={inputEl}
        onChange={e => handleImportLendiCode(e)}
      />
    </Paper>
  );

  useEffect(() => {
    if (lendiCodeOperationCompleted && lendiCodeImportResult.length > 0) {
      // call to our export to csv
      const csvBlob = convertJson2CsvContent(lendiCodeImportResult);
      FileSaver.saveAs(
        csvBlob,
        `${
          process.env.REACT_APP_ENV
        }-lendi-code-import-result-${new Date().toISOString()}.csv`
      );
    }
  }, [lendiCodeImportResult, lendiCodeOperationCompleted]);

  useEffect(() => {
    const lendiCodes = queryResult.data?.listLendiCodes.lendiCodes || [];
    const lendiCodesCopy = [...lendiCodes];
    // sorting alphabetically
    lendiCodesCopy.sort((a, b) =>
      a.lendiCode.toLowerCase().localeCompare(b.lendiCode.toLowerCase())
    );
    setLendiCodes(lendiCodesCopy);
  }, [queryResult]);

  if (isLoading) {
    return <TableLoading />;
  }

  if (isError) {
    // reset error state
    errorDialog?.showErrorDialog(true);
    setError(false);
  }

  return (
    <Container>
      <ButtonContainer>
        <Grid container direction="column" alignItems="flex-start">
          <Grid item xs={12}>
            <Button
              variant={'contained'}
              size={'medium'}
              color={'primary'}
              onClick={() => setNewLendiCodeDialogOpen(true)}>
              Add
            </Button>
            <LendiCodeDialog
              open={newLendiCodeDialogOpen}
              onClose={() => {
                setNewLendiCodeDialogOpen(false);
              }}
              onSave={(data: LendiCode) => {
                save(data);
                setNewLendiCodeDialogOpen(false);
              }}
              data={defaultNewLendiCode}
            />
          </Grid>
        </Grid>
      </ButtonContainer>
      <LendiCodeTable />
    </Container>
  );
};

export default LendiCodeManagementScreen;
