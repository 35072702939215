import React, { useState } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Tooltip from '@material-ui/core/Tooltip';
import { BooleanFlagSearchFilter } from './SearchFilterDetail';
import { defaultBooleanFlagSearchFilter } from './index';

interface FilterCheckboxProps {
  label: string;
  description: string;
  currentValue: BooleanFlagSearchFilter;
  onCheckChange: (value: BooleanFlagSearchFilter) => void;
}

const FilterCheckBox: React.FC<FilterCheckboxProps> = ({
  label,
  description,
  currentValue,
  onCheckChange,
}) => {
  const [booleanFlagSearchFilter, setBooleanFlagSearchFilter] = useState(
    currentValue
  );
  const handleChange = (_: React.ChangeEvent<HTMLInputElement>) => {
    let newState;
    if (!booleanFlagSearchFilter.isFilterOn) {
      // if filter was off, turn filter on and search for positive boolean
      newState = {
        isFilterOn: true,
        booleanFilteredFor: true,
      } as BooleanFlagSearchFilter;
    } else {
      if (booleanFlagSearchFilter.booleanFilteredFor) {
        // if it was searching for positive boolean, then switch to negative boolean
        newState = {
          isFilterOn: true,
          booleanFilteredFor: false,
        } as BooleanFlagSearchFilter;
      } else {
        // If filter was searching negative boolean, switch off filter
        newState = defaultBooleanFlagSearchFilter;
      }
    }
    setBooleanFlagSearchFilter(newState);
    onCheckChange(newState);
  };

  return (
    <Tooltip title={description}>
      {booleanFlagSearchFilter.isFilterOn ? (
        booleanFlagSearchFilter.booleanFilteredFor ? (
          <FormControlLabel
            control={
              <Checkbox
                checked={true}
                color="primary"
                onChange={handleChange}
              />
            }
            label={label}
          />
        ) : (
          <FormControlLabel
            control={
              <Checkbox
                checked={true}
                color="primary"
                indeterminate
                onChange={handleChange}
              />
            }
            label={label}
          />
        )
      ) : (
        <FormControlLabel
          control={
            <Checkbox checked={false} color="primary" onChange={handleChange} />
          }
          label={label}
        />
      )}
    </Tooltip>
  );
};

export default FilterCheckBox;
