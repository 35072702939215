import React from 'react';
import { DocTypeDataProvider } from '../hooks/doctypes';

const url = process.env.REACT_APP_DOCTYPE_API_URL || '';

const withDocTypeProvider = <P extends object>(
  Component: React.ComponentType<P>
) => {
  return (props: any) => {
    return (
      <DocTypeDataProvider url={url}>
        <Component {...(props as P)} />
      </DocTypeDataProvider>
    );
  };
};

export { withDocTypeProvider };
