import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { Rule } from './index';
import styled from 'styled-components';

interface FormProps {
  defaultValues: Rule;
  onSubmit: (rule: Rule) => void;
}

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  align-items: start;
  & > form > * {
    margin-bottom: 10px;
  }
`;

const RuleForm: React.FC<FormProps> = ({
  children,
  defaultValues,
  onSubmit,
}) => {
  const rule = defaultValues;
  const methods = useForm({
    defaultValues: {
      name: rule.name,
      description: rule.description,
      criteria: rule.criteria,
      mitigations: rule.mitigations,
      defaultMitigation: rule.defaultMitigation,
      active: rule.active,
      allowBrokerMitigation: rule.allowBrokerMitigation,
      allowCustomerMitigation: rule.allowCustomerMitigation,
      includedInCoversheet: rule.includedInCoversheet,
      singleCondition: rule.singleCondition,
      appliesToNewLender: rule.appliesToNewLender,
      category: rule.category,
      variationOnly: rule.variationOnly as boolean | undefined,
      lendiCodeRating: rule.lendiCodeRating || undefined,
    },
  });

  const { handleSubmit } = methods;

  return (
    <Container>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          {children}
        </form>
      </FormProvider>
    </Container>
  );
};

export default RuleForm;
