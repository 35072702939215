import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useLenderData, Lender } from '../hooks/lenders';

interface LenderMultiSelectProps {
  label?: string;
  selectedLenderUUIDs: string[];
  onSelectedLenders: (value: string[]) => void;
  disabled?: boolean;
}

const LenderMultiSelect: React.FC<LenderMultiSelectProps> = ({
  label,
  onSelectedLenders,
  selectedLenderUUIDs,
  disabled,
}) => {
  const { lenders } = useLenderData();
  const [selected, setSelected] = useState([] as Lender[]);
  const handleChange = (_: React.ChangeEvent<{}>, value: any) => {
    setSelected(value);
    onSelectedLenders((value as Lender[]).map(funder => funder.id));
  };

  useEffect(() => {
    // always selected from known allFunders
    const getFunderDataBy = (id: string) =>
      lenders && lenders.find(lender => lender.id === id)!;

    selectedLenderUUIDs &&
      setSelected(
        selectedLenderUUIDs.map(s => getFunderDataBy(s)).filter(v => v)
      );
  }, [lenders, selectedLenderUUIDs]);

  return (
    <Autocomplete
      disabled={disabled === undefined ? false : disabled}
      multiple
      size="small"
      options={lenders}
      getOptionLabel={option => option.name}
      value={selected}
      filterSelectedOptions
      onChange={handleChange}
      renderInput={params => (
        <TextField
          {...params}
          size="small"
          variant="standard"
          label={label === undefined ? 'Lenders' : label}
          fullWidth
        />
      )}
    />
  );
};

export default LenderMultiSelect;
