import React, { useRef } from 'react';
import AddBoxIcon from '@material-ui/icons/AddBox';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import { Mitigation } from '../../graphql/types';
import { makeStyles } from '@material-ui/core/styles';
import MitigationTableItem from './MitigationTableItem';
import * as shortid from 'shortid';
import { useFieldArray } from 'react-hook-form';

const useStyles = makeStyles({
  root: {
    margin: '20px 0px',
  },
  tableHeader: {
    display: 'flex',
    'align-items': 'center',
  },
  flat: {
    display: 'flex',
    'align-items': 'center',
  },
  funders: {
    display: 'flex',
    'flex-direction': 'column',
    'justify-content': 'start',
  },
});

interface MitigationTableProps {
  currentMitigations: Mitigation[];
}

const defaultMitigationWithId = () => ({
  id: shortid.generate(),
  description: '',
  lenders: [],
  mitigationRequirement: {
    allowOverride: true,
    hardstop: false,
    commentRequired: true,
    requiredDocTypes: [],
  },
});

const MitigationTable: React.FC<MitigationTableProps> = React.forwardRef(
  ({ currentMitigations }, ref) => {
    const classes = useStyles();
    const bottomRef = useRef<HTMLTableRowElement>(null);
    const newMitigation = () => {
      append(defaultMitigationWithId());
    };

    const removeMitigation = (index: number) => {
      remove(index);
    };

    const renderRow = (mitigationWithId: any, index: number) => {
      return (
        <TableRow key={`${mitigationWithId.id}-${index}`}>
          <TableCell>
            <div className={classes.flat}>
              policy {index + 1}
              <IconButton
                aria-label="settings"
                onClick={() => removeMitigation(index)}>
                <DeleteForeverIcon />
              </IconButton>
            </div>
          </TableCell>
          <TableCell>
            <MitigationTableItem
              index={index}
              mitigation={mitigationWithId || defaultMitigationWithId}
            />
          </TableCell>
        </TableRow>
      );
    };

    const { fields, append, remove } = useFieldArray({
      name: 'mitigations',
    });

    return (
      <TableContainer className={classes.root}>
        <div className={classes.tableHeader}>
          <Typography>Mitigation Policies</Typography>
          <IconButton aria-label="settings" onClick={newMitigation}>
            <AddBoxIcon />
          </IconButton>
        </div>
        <Table>
          <TableBody>
            {fields.map((mitigation, index) => renderRow(mitigation, index))}
          </TableBody>
        </Table>
        <div ref={bottomRef} />
      </TableContainer>
    );
  }
);

export default MitigationTable;
