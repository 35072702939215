import React, { useState } from 'react';
import { Prompt } from 'react-router';
import RuleDetail, { Rule, DefaultLendiCode } from '../RuleDetail';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import styled from 'styled-components';
import {
  Action,
  DifficultyRating,
  NewRuleInput,
  useNewRuleMutation,
} from '../../graphql/types';
import { useErrorDialog } from '../../hooks/error-dialog';
import Loading from '../Loading';
import { useHistory } from 'react-router-dom';
import { sanitiseRule } from '../utils/sanitisers';
import { validate } from '../../services';
import RuleForm from '../RuleDetail/RuleForm';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 36px;
  width: 100%;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  width: 100%;
`;

const NewRuleScreen: React.FC = () => {
  const [rule] = useState({
    name: '',
    description: '',
    active: true,
    category: '',
    allowBrokerMitigation: true,
    allowCustomerMitigation: true,
    includedInCoversheet: true,
    singleCondition: true,
    appliesToNewLender: true,
    mitigations: [],
    variationOnly: false,
    defaultMitigation: {
      action: Action.NoActionRequired,
      description: '',
    },
    lendiCodeRating: {
      lendiCode: DefaultLendiCode,
      defaultRating: DifficultyRating.Off,
      ratings: [],
    },
  } as Rule);

  const errorDialog = useErrorDialog();
  const [newRule] = useNewRuleMutation();
  const [loading, setLoading] = useState(false);
  const [showPrompt, setShowPrompt] = useState(true);
  const history = useHistory();

  const displayError = (errorMessage: string) => {
    errorDialog?.showErrorDialog(true);
    errorDialog?.setErrorDialogContent(errorMessage);
  };

  const showLoading = () => {
    setLoading(true);
    setShowPrompt(false);
  };

  const hideLoading = () => {
    setLoading(false);
  };

  const createRule = (rule: Rule) => {
    return newRule({
      variables: {
        rule: { ...sanitiseRule(rule) } as NewRuleInput,
      },
    });
  };

  const save = (rule: Rule) => {
    showLoading();
    return validate(rule.criteria)
      .then(() => createRule(rule))
      .then(() => history.push('/', { refresh: true }))
      .catch(error => displayError(error))
      .finally(() => hideLoading());
  };

  return (
    <Container>
      <Prompt
        when={showPrompt}
        message="You might have unsaved changes, are you sure you want to leave?"
      />
      <RuleForm defaultValues={rule} onSubmit={save}>
        <RuleDetail rule={rule} />
        <Divider />
        <ButtonContainer>
          <Button type={'submit'}>Create Rule</Button>
        </ButtonContainer>
        {loading && <Loading />}
      </RuleForm>
    </Container>
  );
};

export default NewRuleScreen;
