import {
  Maybe,
  MitigationRequirement,
  MitigationRequirementInput,
  DefaultMitigation,
  DefaultMitigationInput,
  Mitigation,
  MitigationInput,
  Rule,
  UpdateRuleInput,
  NewRuleInput,
  LendiCodeRating,
  LendiCodeRatingInput,
  LendiRatingInput,
  LendiRating,
} from '../../../graphql/types';

export const toMitigationRequirementUpdate = (
  mitigationRequirement: Maybe<MitigationRequirement> | undefined
) => {
  if (mitigationRequirement === undefined || mitigationRequirement === null) {
    return null;
  }
  return {
    allowOverride: mitigationRequirement.allowOverride,
    hardstop: mitigationRequirement.hardstop,
    commentRequired: mitigationRequirement.commentRequired,
    requiredDocTypes: mitigationRequirement.requiredDocTypes,
  } as MitigationRequirementInput;
};

export const toDefaultMitigationUpdate = (
  defaultMitigation: DefaultMitigation
) => {
  return {
    action: defaultMitigation.action,
    description: defaultMitigation.description,
    mitigationRequirement: toMitigationRequirementUpdate(
      defaultMitigation.mitigationRequirement
    ),
  } as DefaultMitigationInput;
};

export const toMitigationUpdate = (mitigation: Mitigation) => {
  return {
    description: mitigation.description,
    lenders: mitigation.lenders,
    mitigationRequirement: toMitigationRequirementUpdate(
      mitigation.mitigationRequirement
    ),
  } as MitigationInput;
};

const toLendiRatingInput = (rating: LendiRating) => {
  return {
    lenders: rating.lenders,
    rating: rating.rating,
  } as LendiRatingInput;
};

const toLendiCodeRatingInput = (
  lendiCodeRating: LendiCodeRating | null | undefined
) => {
  if (lendiCodeRating) {
    return {
      lendiCode: lendiCodeRating.lendiCode,
      ratings: lendiCodeRating.ratings.map(rating =>
        toLendiRatingInput(rating)
      ),
      defaultRating: lendiCodeRating.defaultRating,
    } as LendiCodeRatingInput;
  }
  return null;
};

export const toRuleUpdate = (importedRule: Rule, expectedVersion: number) => {
  return {
    expectedVersion,
    id: importedRule.id,
    name: importedRule.name,
    description: importedRule.description,
    criteria: importedRule.criteria,
    mitigations: importedRule.mitigations.map(toMitigationUpdate),
    defaultMitigation: toDefaultMitigationUpdate(
      importedRule.defaultMitigation
    ),
    active: importedRule.active,
    allowBrokerMitigation: importedRule.allowBrokerMitigation,
    allowCustomerMitigation: importedRule.allowCustomerMitigation,
    includedInCoversheet: importedRule.includedInCoversheet,
    singleCondition: importedRule.singleCondition,
    appliesToNewLender: importedRule.appliesToNewLender,
    category: importedRule.category,
    variationOnly: importedRule.variationOnly || false,
    lendiCodeRating: toLendiCodeRatingInput(importedRule.lendiCodeRating),
  } as UpdateRuleInput;
};

export const toRuleCreate = (importedRule: Rule) => {
  return {
    id: importedRule.id,
    name: importedRule.name,
    description: importedRule.description,
    criteria: importedRule.criteria,
    mitigations: importedRule.mitigations.map(toMitigationUpdate),
    defaultMitigation: toDefaultMitigationUpdate(
      importedRule.defaultMitigation
    ),
    active: importedRule.active,
    allowBrokerMitigation: importedRule.allowBrokerMitigation,
    allowCustomerMitigation: importedRule.allowCustomerMitigation,
    includedInCoversheet: importedRule.includedInCoversheet,
    singleCondition: importedRule.singleCondition,
    appliesToNewLender: importedRule.appliesToNewLender,
    category: importedRule.category,
    variationOnly: importedRule.variationOnly || false,
    lendiCodeRating: toLendiCodeRatingInput(importedRule.lendiCodeRating),
  } as NewRuleInput;
};
