import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';
import DescriptionEditor from './DescriptionEditor';
import CriteriaEditor from './CriteriaEditor';
import {
  Mitigation,
  DefaultMitigation,
  Category,
  Action,
  LendiCodeRating,
  DifficultyRating,
} from '../../graphql/types';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Switch,
} from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form';
import RuleDetailsTabs from '../Shared/RulesScreen/RuleDetailsTabs';
import { lendiCodeTableRender } from './LendiCodeTable';
import { mitigationTabPanelRender } from './MitigationTabPanel';

const NameContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
`;

export interface Rule {
  id?: string;
  version?: number;
  createdAt?: string;
  updatedAt?: string;
  name?: string;
  description?: string;
  criteria?: string;
  mitigations: Mitigation[];
  defaultMitigation: DefaultMitigation;
  active: boolean;
  allowBrokerMitigation: boolean;
  allowCustomerMitigation: boolean;
  includedInCoversheet: boolean;
  singleCondition: boolean;
  appliesToNewLender: boolean;
  category?: string;
  variationOnly?: boolean | null;
  lendiCodeRating?: LendiCodeRating | null;
}

interface RuleDetailProps {
  rule: Rule;
}

/**
 * Validate at least one mitigation policy has been configured
 *
 * @param mitigations - mitigations selected
 * @param defaultMitigation - defaultMitigation selected
 */
const validateRuleMitigationPolicyConfigured = (
  mitigations: Array<Mitigation>,
  defaultMitigation: DefaultMitigation
): boolean =>
  mitigations.length <= 0 &&
  defaultMitigation.action === Action.NoActionRequired;

const lendiCodeConfigured = (lendiCode: string): boolean =>
  lendiCode !== DefaultLendiCode;

const validateRuleMitigationPolicyAndLendiCodeConfigured = (
  mitigations: Array<Mitigation>,
  defaultMitigation: DefaultMitigation,
  lendiCode: string
): boolean => {
  return (
    !validateRuleMitigationPolicyConfigured(mitigations, defaultMitigation) ||
    lendiCodeConfigured(lendiCode)
  );
};

/**
 * RuleDetails component display current rule with its information
 * @param rule - rule to render
 * @param onRuleReady - callback function from parent when rules are ready to perform action
 * @constructor
 */
const RuleDetail: React.FC<RuleDetailProps> = ({ rule }) => {
  const {
    register,
    setValue,
    setError,
    getValues,
    clearErrors,
    formState: { errors },
  } = useFormContext();

  const [forNewLender, setForNewLender] = useState(rule.appliesToNewLender);

  useEffect(() => {
    register('name', { required: true });
    register('description', { required: true });
    register('criteria', { required: true });
    register('category', { required: true });
    register('defaultMitigation', {
      required: true,
      validate: {
        isRuleMitigationPolicyOrLendiCodeConfigured: defaultMitigation => {
          const mitigations = getValues('mitigations');
          const lendiCode = getValues('lendiCodeRating.lendiCode');
          return validateRuleMitigationPolicyAndLendiCodeConfigured(
            mitigations,
            defaultMitigation,
            lendiCode
          );
        },
      },
    });
  }, [register, getValues]);

  const mitigationTabPanelRender = () =>
    mitigationTabPanel({ forNewLender, rule });
  const lendiCodeTabPanelRender = () => lendiCodeTabPanel({ rule });

  return (
    <React.Fragment>
      {(rule.version || rule.id) && (
        <div>
          {rule.version && <Typography>Version No {rule.version}</Typography>}
          {rule.id && <Typography>ID {rule.id}</Typography>}
        </div>
      )}
      <NameContainer>
        <Controller
          name="name"
          render={({ field }) => (
            <TextField label="Rule name" fullWidth {...field} />
          )}
        />
        <Controller
          name="active"
          render={({ field }) => (
            <FormControlLabel
              value="Active"
              control={
                <Switch
                  onChange={e => field.onChange(e.target.checked)}
                  checked={field.value}
                />
              }
              label="Active"
              labelPlacement="start"
            />
          )}
        />
      </NameContainer>
      {errors.name && <Alert severity="warning">Rule name is required!</Alert>}
      <DescriptionEditor
        label="Rule description"
        currentValue={rule.description}
        onDescriptionChange={description => {
          clearErrors('description');
          setValue('description', description);
        }}
      />
      {errors.description && (
        <Alert severity="warning">Rule Description is required!</Alert>
      )}
      <FormControl fullWidth>
        <InputLabel>Category</InputLabel>
        <Controller
          name="category"
          render={({ field }) => (
            <Select {...field}>
              {Object.values(Category).map(c => (
                <MenuItem key={c} value={c}>
                  {c}
                </MenuItem>
              ))}
            </Select>
          )}
        />
      </FormControl>
      {errors.category && (
        <Alert severity="warning">Rule Category is required!</Alert>
      )}
      <Controller
        name="allowBrokerMitigation"
        render={({ field }) => (
          <FormControlLabel
            control={
              <Checkbox
                checked={field.value}
                onChange={e => field.onChange(e.target.checked)}
              />
            }
            label="Broker mitigable"
          />
        )}
      />

      <Controller
        name="allowCustomerMitigation"
        render={({ field }) => (
          <FormControlLabel
            control={
              <Checkbox
                checked={field.value}
                onChange={e => field.onChange(e.target.checked)}
              />
            }
            label="Customer mitigable"
          />
        )}
      />

      <Controller
        name="includedInCoversheet"
        render={({ field }) => (
          <FormControlLabel
            control={
              <Checkbox
                checked={field.value}
                onChange={e => field.onChange(e.target.checked)}
              />
            }
            label="Included in Coversheet"
          />
        )}
      />

      <Controller
        name="singleCondition"
        render={({ field }) => (
          <FormControlLabel
            control={
              <Checkbox
                checked={field.value}
                onChange={e => field.onChange(e.target.checked)}
              />
            }
            label="Single condition"
          />
        )}
      />

      <Controller
        name="appliesToNewLender"
        render={({ field }) => (
          <FormControlLabel
            control={
              <Checkbox
                checked={field.value}
                onChange={e => {
                  setForNewLender(e.target.checked);
                  field.onChange(e.target.checked);
                }}
              />
            }
            label="For new lender"
          />
        )}
      />

      <Controller
        name="variationOnly"
        render={({ field }) => (
          <FormControlLabel
            control={
              <Checkbox
                checked={field.value}
                onChange={e => field.onChange(e.target.checked)}
              />
            }
            label="Variation only"
          />
        )}
      />

      <CriteriaEditor
        label="Criteria"
        currentValue={rule.criteria}
        onCriteriaChange={criteria => {
          clearErrors('criteria');
          if (!criteria) {
            setError('criteria', {
              message: 'This field is required',
              type: 'required',
            });
          } else {
            setValue('criteria', criteria);
          }
        }}
      />
      {errors.criteria && (
        <Alert severity="warning">Rule Criteria is required</Alert>
      )}
      <RuleDetailsTabs
        mitigationTabPanel={mitigationTabPanelRender}
        lendiCodePanel={lendiCodeTabPanelRender}
        rule={rule}
        forNewLender={forNewLender}
      />
    </React.Fragment>
  );
};

interface MitigationTabPanelProps {
  forNewLender: boolean;
  rule: Rule;
}

interface LendiCodeTabPanelProps {
  rule: Rule;
}

export const DefaultLendiCode = 'Off';
export const DefaultRating = DifficultyRating.Off.toString();

const defaultLendiCodeRating = {
  lendiCode: DefaultLendiCode,
  ratings: [],
  defaultRating: DifficultyRating.Off,
};

const mitigationTabPanel = ({ forNewLender, rule }: MitigationTabPanelProps) =>
  mitigationTabPanelRender({ forNewLender, rule });
const lendiCodeTabPanel = ({ rule }: LendiCodeTabPanelProps) =>
  // create default lendiCodeRating to render tab if not provided
  lendiCodeTableRender(rule.lendiCodeRating || defaultLendiCodeRating);

export default RuleDetail;
