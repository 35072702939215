import React, { useState } from 'react';
import { Action } from '../../graphql/types';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

const ActionList = Object.values(Action);

interface ActionSelectProps {
  currentSelected?: string;
  onActionSelected: (value: string | null) => void;
}

const ActionSelect: React.FC<ActionSelectProps> = ({
  currentSelected,
  onActionSelected,
}) => {
  const [selected, setSelected] = useState(currentSelected);
  const handleChange = (_: React.ChangeEvent<{}>, value: string | null) => {
    setSelected(value!);
    onActionSelected(value);
  };

  return (
    <Autocomplete
      size="small"
      options={ActionList}
      value={selected || ''}
      onChange={handleChange}
      disableClearable
      renderInput={params => (
        <TextField {...params} label="Action" variant="standard" fullWidth />
      )}
    />
  );
};

export default ActionSelect;
