import { Rule } from '../RuleDetail';
import { Mitigation } from '../../graphql/types';

type Maybe<T> = T | null | undefined;
const convertEmptyStringToNull = (value?: Maybe<string>): Maybe<string> => {
  if (value === '' || value === undefined) {
    return null;
  }
  return value;
};

interface MitigationWithMayBeId extends Mitigation {
  id?: string;
}

export const sanitiseRule = (rule: Rule): Rule => {
  let updatedRule = rule;

  updatedRule.defaultMitigation.description = rule.defaultMitigation.description
    ? convertEmptyStringToNull(rule.defaultMitigation.description)
    : null;

  updatedRule.mitigations.map((mitigation: MitigationWithMayBeId) => {
    mitigation.description = mitigation.description
      ? convertEmptyStringToNull(mitigation.description)
      : null;

    if (mitigation.hasOwnProperty('id')) {
      delete mitigation['id'];
    }
    return mitigation;
  });
  // if variationOnly is not set to true, we
  updatedRule.variationOnly =
    rule.variationOnly === true ? rule.variationOnly : false;

  return updatedRule;
};
