import * as Applicant from './schema/applicant.schema.json';
import * as Application from './schema/application.schema.json';
import * as Asset from './schema/asset.schema.json';
import * as Expense from './schema/expense.schema.json';
import * as PropertyExpense from './schema/property.expense.schema.json';
import * as Funding from './schema/funding.position.schema.json';
import * as Income from './schema/income.schema.json';
import * as Liability from './schema/liability.schema.json';
import * as Product from './schema/product.schema.json';
import * as Security from './schema/security.schema.json';
import * as Servicing from './schema/servicing.schema.json';
import * as HouseHold from './schema/household.schema.json';
import * as Credit from './schema/credit.report.schema.json';
import * as IacMaxBorrowing from './schema/iac.max.borrowing.schema.json';
import Enums from './schema/definition.types.schema.json';
import * as IacHouseHold from './schema/iac.household.schema.json';
import * as SelfEmployedBusiness from './schema/self.employed.income.schema.json';
import * as CashOut from './schema/cash.out.schema.json';
import * as Guarantor from './schema/guarantor.schema.json';
import * as Company from './schema/company.schema.json';
import * as CompanyContactPerson from './schema/company.contact.person.schema.json';
import * as Trust from './schema/trust.schema.json';

export type FactSchema = {
  name: string;
  properties: Array<FactType>;
};

export type FactType = {
  name: string;
};

const schemas: any[] = [
  Applicant,
  Application,
  Asset,
  Expense,
  PropertyExpense,
  Funding,
  Income,
  Liability,
  Product,
  Security,
  Servicing,
  HouseHold,
  Credit,
  IacMaxBorrowing,
  IacHouseHold,
  SelfEmployedBusiness,
  CashOut,
  Guarantor,
  Company,
  CompanyContactPerson,
  Trust,
];

/**
 * @return a Map<EnumName, List of enum values>
 */
const loadFactsEnums = (): Map<string, Array<string>> => {
  const map: Map<string, Array<string>> = new Map();
  for (let [key, value] of Object.entries(Enums.definitions) as any) {
    map.set(key, value.enum || []);
  }
  return map;
};

const factsEnums: Map<string, Array<string>> = loadFactsEnums();

interface FactEnumMapping {
  factName: string;
  // Map<propertyName, enumName>
  mapping: Map<string, string>;
}

const loadFactToEnumMapping = (
  factSchema: any
): FactEnumMapping | undefined => {
  const mapping: Map<string, string> = new Map<string, string>();
  let factName = factSchema.default?.title;
  // According to schema, we might have properties for multiple facts, but we are only interested in one
  const schemaProperties = factSchema.default?.properties || {};
  const factNamesInRootObject: Array<string> = Object.keys(schemaProperties);

  if (factNamesInRootObject.length <= 0) {
    return undefined;
  }

  if (!factName) {
    factName = factNamesInRootObject[0];
  }

  const factProperties = schemaProperties[factName]?.properties || {};
  Object.entries(factProperties).forEach(entry => {
    const propertyName: string = entry[0];
    // @ts-ignore
    // prettier-ignore
    const $ref: string = entry[1].type === 'array' && entry[1].items ? entry[1].items.$ref : entry[1].$ref;

    if ($ref) {
      const enumName: string = $ref.substr($ref.lastIndexOf('/') + 1);
      mapping.set(propertyName, enumName);
    }
  });
  return {
    factName,
    mapping,
  };
};

/**
 * @return a Map<factName, Map<propertyName, enumName>>
 */
const loadAllFactsToEnumMapping = (): Map<string, Map<string, string>> => {
  const mapping: Map<string, Map<string, string>> = new Map<
    string,
    Map<string, string>
  >();
  schemas.forEach(fact => {
    const factEnumMapping: FactEnumMapping | undefined = loadFactToEnumMapping(
      fact
    );
    if (factEnumMapping && factEnumMapping.mapping.size > 0) {
      mapping.set(factEnumMapping.factName, factEnumMapping.mapping);
    }
  });
  return mapping;
};

const factsEnumLookup: Map<
  string,
  Map<string, string>
> = loadAllFactsToEnumMapping();

/**
 * Find autocompletion suggestions based on factName and propertyName
 *
 * @param factName
 * @param propertyName
 */
export const findEnumSuggestion = (
  factName: string,
  propertyName: string
): Array<string> => {
  const enumName: string =
    (factsEnumLookup.get(factName) || new Map<string, string>()).get(
      propertyName
    ) || '';
  return (factsEnums.get(enumName) || []).map(
    enumValue => `${enumName}.${enumValue}`
  );
};

/**
 * Coverting jsonschema to json document for R.A.T autosuggestions.
 * Expected `title` field for autosuggestion label, if missing,
 * it will fall back to the first object key from properties of root object.
 *
 * @param schemas Array of facts model schema suppiled by gradle task
 * */
export const suggestionsGenerator = (
  schemas: Array<any>
): Array<FactSchema> => {
  return schemas.flatMap(schema => {
    let name = schema.default.title;
    const schemaProperties = schema.default.properties;
    const schemaRootObj = Object.keys(schemaProperties);

    if (!name) {
      name = schemaRootObj[0];
      console.warn(`Missing factschema title on ${name}`);
    }

    return {
      name,
      properties: schemaRootObj.flatMap(key => {
        const types = Object.keys(schemaProperties[key].properties);
        return types.map((type: string) => ({ name: type }));
      }),
    };
  });
};

const suggestionsProvider = suggestionsGenerator(schemas);

export default suggestionsProvider;
