import React, { useRef, useEffect } from 'react';
import * as monaco from 'monaco-editor';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import { droolLanguage } from '../../config/drl-lang';

const Container = styled.div`
  display: flex;
  height: 10em;
`;

interface CriteriaEditorProps {
  currentValue?: string;
  onCriteriaChange: (value: string) => void;
  label: string;
}

const CriteriaEditor: React.FC<CriteriaEditorProps> = ({
  currentValue,
  onCriteriaChange,
  label,
}) => {
  const monacoEditorRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const model = monaco.editor.create(
      monacoEditorRef?.current as HTMLElement,
      {
        value: currentValue,
        language: droolLanguage,
        theme: 'vs-dark',
        minimap: {
          enabled: false,
        },
      }
    );

    model.onDidChangeModelContent(_ => onCriteriaChange(model.getValue()));

    return () => {
      model.dispose();
    };

    // eslint-disable-next-line
  }, []);
  return (
    <div>
      <Typography>{label}</Typography>
      <Container ref={monacoEditorRef} />
    </div>
  );
};

export default CriteriaEditor;
