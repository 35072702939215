import { useEffect, useRef } from 'react';

// the purpose of this hook is to have as the same ability as useEffect
// but there should be no effect on the initial render
const useDidMountEffect = (
  func: () => void | (() => void | undefined),
  deps: ReadonlyArray<any>
) => {
  const didMount = useRef(false);
  useEffect(() => {
    if (didMount.current) {
      func();
    } else {
      didMount.current = true;
    }
  }, deps); // eslint-disable-line react-hooks/exhaustive-deps
};

export default useDidMountEffect;
