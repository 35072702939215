import React, { useState } from 'react';
import { Category } from '../../graphql/types';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

const CategoryList = Object.values(Category);

interface CategorySelectProps {
  currentSelected?: string;
  onCategorySelected: (value: string | null) => void;
}

const CategorySelect: React.FC<CategorySelectProps> = ({
  currentSelected,
  onCategorySelected,
}) => {
  const [selected, setSelected] = useState(currentSelected);
  const handleChange = (_: React.ChangeEvent<{}>, value: string | null) => {
    setSelected(value!);
    onCategorySelected(value);
  };

  return (
    <Autocomplete
      size="small"
      options={CategoryList}
      value={selected || ''}
      onChange={handleChange}
      renderInput={params => (
        <TextField {...params} label="Category" variant="standard" fullWidth />
      )}
    />
  );
};

export default CategorySelect;
