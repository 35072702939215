import React, { useState, useRef, useEffect } from 'react';
import {
  useUpdateRuleMutation,
  Rule,
  useNewRuleMutation,
} from '../../../graphql/types';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import IconButton from '@material-ui/core/IconButton';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import ISOSwitch from '../../ISOSwitch';
import { useErrorDialog } from '../../../hooks/error-dialog';
import { useDocTypeData } from '../../../hooks/doctypes';
import { useLenderData } from '../../../hooks/lenders';
import EditRuleDialog from './EditRuleDiaglog';
import Checkbox from '@material-ui/core/Checkbox';
import * as FileSaver from 'file-saver';
import { toRuleUpdate, toRuleCreate } from './ImportConverters';
import RulesToolBar from './RulesToolBar';
import { toRuleCsv, toLendiCodeCsv } from './CsvConverter';
import { RuleOperationResult, OperationResult } from './types';
import { convertJson2CsvContent } from '../../../services';
import { Alert } from '@material-ui/lab';
import { Snackbar } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    height: '100%',
    maxHeight: '100%',
  },
  chip: {
    margin: '0 4px',
  },
  dot: {
    'font-size': '2em',
    'font-weight': '600',
  },
  export: {
    display: 'flex',
    'align-items': 'center',
  },
  fileInput: {
    display: 'none',
  },
});

interface RulesTableProps {
  rules: Rule[];
  refetch: (_?: any) => Promise<any>;
  rulesTableName?: string;
}

const RulesTable: React.FC<RulesTableProps> = ({
  rules,
  refetch,
  rulesTableName,
}) => {
  const classes = useStyles();
  const { docTypes } = useDocTypeData();
  const { lenders } = useLenderData();
  const [currentEditingRule, setCurrentEditingRule] = useState<Rule | null>(
    null
  );
  const [selectedRules, setSelectedRules] = useState([] as Rule[]);
  const [rulesOperationResults, setRulesOperationResults] = useState(
    [] as RuleOperationResult[]
  );
  const [ruleOperationInProgress, setRuleOperationInProgress] = useState(false);
  const [ruleOperationCompleted, setRuleOperationCompleted] = useState(false);
  const errorDialog = useErrorDialog();
  const [updateRule] = useUpdateRuleMutation();
  const [newRule] = useNewRuleMutation();
  const lenderDict = lenders.reduce((result, lender) => {
    result[lender.id] = lender.name;
    return result;
  }, {} as { [_: string]: string });
  const inputEl = useRef<HTMLInputElement | null>(null);

  const ruleLendersDict = rules
    .map(rule => {
      return {
        id: rule.id,
        lenders: rule.mitigations.reduce(
          (lenders, mitigation) => lenders.concat(mitigation.lenders),
          [] as string[]
        ),
      };
    })
    .reduce((result, ruleLenders) => {
      result[ruleLenders.id] = ruleLenders.lenders;
      return result;
    }, {} as { [_: string]: string[] });

  const flipRuleStatus = (
    ruleId: string,
    expectedVersion: number,
    active: boolean
  ) => {
    return updateRule({
      variables: {
        rule: {
          id: ruleId,
          expectedVersion,
          active: !active,
        },
      },
    }).catch(_ => {
      errorDialog?.showErrorDialog(true);
    });
  };

  const handleCloseDialog = (refresh: boolean) => {
    setCurrentEditingRule(null);
    if (refresh) {
      return refetch().catch(_ => {
        errorDialog?.showErrorDialog(true);
      });
    }
  };

  const handleUnselectAll = () => {
    setSelectedRules([]);
  };
  const handleSelectAll = () => {
    setSelectedRules(rules);
  };
  const handleToggleSelectRule = (rule: Rule) => {
    if (selectedRules.includes(rule))
      setSelectedRules(selectedRules.filter(r => r.id !== rule.id));
    else setSelectedRules([...selectedRules, rule]);
  };

  const handleExportRule = () => {
    const blob = new Blob([JSON.stringify(selectedRules, null, 1)], {
      type: 'application/json;charset=utf-8',
    });
    FileSaver.saveAs(
      blob,
      `${process.env.REACT_APP_ENV}-rules-${new Date().toISOString()}.json`
    );
  };

  /* Export to CSV file */
  const handleExportLendiCodeToCsv = () => {
    const csv = toLendiCodeCsv(selectedRules, lenderDict);
    const csvBlob = new Blob([csv], {
      type: 'text/csv;charset=utf-8',
    });
    FileSaver.saveAs(
      csvBlob,
      `${process.env.REACT_APP_ENV}-lendi-code-${new Date().toISOString()}.csv`
    );
  };

  const handleExportRuleToCsv = () => {
    const csv = toRuleCsv(selectedRules, lenderDict, docTypes);
    const csvBlob = new Blob([csv], {
      type: 'text/csv;charset=utf-8',
    });
    FileSaver.saveAs(
      csvBlob,
      `${process.env.REACT_APP_ENV}-rules-${new Date().toISOString()}.csv`
    );
  };

  const handleImportRuleClicked = () => {
    inputEl.current!.click();
  };

  const handleRuleFileAdded = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRuleOperationInProgress(true);
    setRuleOperationCompleted(false);
    const file = event.target.files!.item(0);
    if (file !== null) {
      const fileReader = new FileReader();
      fileReader.onload = function(e) {
        const raw = fileReader.result;
        const json = JSON.parse(raw!.toString());
        const importedRules = json as Rule[];
        let execRules = [] as RuleOperationResult[];
        importedRules.forEach(importedRule => {
          // Assume `rules` contains all rules
          const existingRule = rules.find(rule => rule.id === importedRule.id);
          const ruleExists = existingRule !== undefined;
          let operationResult = OperationResult.SUCCESS;
          let errorMessage = '';
          if (ruleExists) {
            const expectedVersion = existingRule!.version;
            updateRule({
              variables: {
                rule: toRuleUpdate(importedRule, expectedVersion),
              },
            }).catch(err => {
              operationResult = OperationResult.FAILED;
              errorMessage = err.message;
              errorDialog?.showErrorDialog(true);
            });
          } else {
            newRule({
              variables: {
                rule: toRuleCreate(importedRule),
              },
            }).catch(err => {
              operationResult = OperationResult.FAILED;
              errorDialog?.showErrorDialog(true);
              errorMessage = err.message;
            });
          }
          execRules.push({
            ruleId: importedRule.id,
            ruleName: importedRule.name,
            operationResult,
            errorMessage,
          });
          if (
            execRules.length === importedRules.length &&
            execRules.length !== 0
          ) {
            setRuleOperationInProgress(false);
            setRulesOperationResults(execRules);
            setRuleOperationCompleted(true);
          }
        });
        refetch().catch(_ => {
          errorDialog?.showErrorDialog(true);
        });
      };
      fileReader.readAsText(file, 'application/json;charset=utf-8');
    }
  };

  const isProductionEnvironment = process.env.REACT_APP_ENV === 'production';

  useEffect(() => {
    if (ruleOperationCompleted && rulesOperationResults.length > 0) {
      // call to our export to csv
      const csvBlob = convertJson2CsvContent(rulesOperationResults);
      FileSaver.saveAs(
        csvBlob,
        `${
          process.env.REACT_APP_ENV
        }-rules-import-result-${new Date().toISOString()}.csv`
      );
    }
  }, [rulesOperationResults, ruleOperationCompleted]);

  return (
    <Paper className={classes.root} elevation={3}>
      <RulesToolBar
        selectedRules={selectedRules}
        handleExportRule={handleExportRule}
        handleExportRuleToCsv={handleExportRuleToCsv}
        handleExportLendiCodeToCsv={handleExportLendiCodeToCsv}
        handleImportRuleClicked={handleImportRuleClicked}
        ruleTableName={rulesTableName}
      />
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={ruleOperationInProgress}
        autoHideDuration={500}>
        <Alert severity="info">Rule operation in progress!</Alert>
      </Snackbar>
      <TableContainer className={classes.container}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell size={'small'}>
                <div className={classes.export}>
                  <Checkbox
                    checked={selectedRules.length !== 0}
                    onChange={() =>
                      selectedRules.length === 0
                        ? handleSelectAll()
                        : handleUnselectAll()
                    }
                    color="primary"
                    indeterminate={
                      selectedRules.length > 0 &&
                      selectedRules.length !== rules.length
                    }
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                </div>
              </TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Lenders</TableCell>
              <TableCell>Category</TableCell>
              {!isProductionEnvironment && <TableCell>Status</TableCell>}
              <TableCell>Version No.</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rules
              .sort((rule1, rule2) => {
                return rule1.name
                  .toLowerCase()
                  .localeCompare(rule2.name.toLowerCase());
              })
              .map(rule => {
                return (
                  <TableRow key={rule.id}>
                    <TableCell>
                      <Checkbox
                        checked={selectedRules.includes(rule)}
                        onChange={() => handleToggleSelectRule(rule)}
                        color="primary"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                    </TableCell>
                    <TableCell>
                      <IconButton onClick={() => setCurrentEditingRule(rule)}>
                        {!isProductionEnvironment ? (
                          <EditIcon />
                        ) : (
                          <VisibilityIcon />
                        )}
                      </IconButton>
                      <span>{rule.name}</span>
                    </TableCell>
                    <TableCell>
                      {ruleLendersDict[rule.id].length > 0 &&
                        ruleLendersDict[rule.id]
                          .slice(0, 3)
                          .map(lenderUUID =>
                            lenderDict[lenderUUID] ? (
                              <Chip
                                key={lenderUUID}
                                size="small"
                                label={lenderDict[lenderUUID]}
                                className={classes.chip}
                              />
                            ) : null
                          )}
                      {ruleLendersDict[rule.id].length > 3 && (
                        <span className={classes.dot}>...</span>
                      )}
                      {ruleLendersDict[rule.id].length === 0 && (
                        <Chip size="small" label="All" />
                      )}
                    </TableCell>
                    <TableCell>{rule.category}</TableCell>
                    {!isProductionEnvironment && (
                      <TableCell>
                        <ISOSwitch
                          checked={rule.active}
                          color="primary"
                          onChange={() =>
                            flipRuleStatus(rule.id, rule.version, rule.active)
                          }
                        />
                      </TableCell>
                    )}
                    <TableCell>{rule.version}</TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        {currentEditingRule ? (
          <EditRuleDialog
            currentRule={currentEditingRule}
            onCloseDialog={refresh => handleCloseDialog(refresh)}
            readOnly={isProductionEnvironment}
          />
        ) : null}
      </TableContainer>
      <input
        type="file"
        className={classes.fileInput}
        ref={inputEl}
        onChange={e => handleRuleFileAdded(e)}
      />
    </Paper>
  );
};

export default RulesTable;
