import React from 'react';
import TextField from '@material-ui/core/TextField';
import styled from 'styled-components';
import { Controller, useFormContext } from 'react-hook-form';
import Divider from '@material-ui/core/Divider';
import { Alert } from '@material-ui/lab';

const NameContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
`;

const LendiCodeForm: React.FC = ({ children }) => {
  const {
    formState: { errors },
  } = useFormContext();

  return (
    <React.Fragment>
      <form noValidate autoComplete="off">
        <NameContainer>
          <Controller
            name="lendiCode"
            rules={{ required: true }}
            render={({ field }) => (
              <TextField label="Lendi Code Name" fullWidth {...field} />
            )}
          />
        </NameContainer>
        {errors.lendiCode && (
          <Alert severity="warning">Lendi Code is required!</Alert>
        )}
        <NameContainer>
          <Controller
            name="description"
            render={({ field }) => (
              <TextField
                label="Description"
                fullWidth
                multiline
                minRows={2}
                {...field}
              />
            )}
          />
        </NameContainer>
        <Divider />
        {children}
      </form>
    </React.Fragment>
  );
};

export default LendiCodeForm;
