import React, { useState } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Tooltip from '@material-ui/core/Tooltip';

interface SimpleCheckboxProps {
  label: string;
  description: string;
  currentValue?: boolean;
  onCheckChange: (value: boolean) => void;
}

const SimpleCheckBox: React.FC<SimpleCheckboxProps> = ({
  label,
  description,
  currentValue,
  onCheckChange,
}) => {
  const [checked, setChecked] = useState(currentValue);
  const handleChange = (
    _: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setChecked(checked);
    onCheckChange(checked);
  };
  return (
    <Tooltip title={description}>
      <FormControlLabel
        control={
          <Checkbox checked={checked} color="primary" onChange={handleChange} />
        }
        label={label}
      />
    </Tooltip>
  );
};

export default SimpleCheckBox;
