import Dialog from '@material-ui/core/Dialog';
import { DialogTitle } from '@material-ui/core';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import React from 'react';
import styled from 'styled-components';
import { LendiCode } from '../../../graphql/types';
import LendiCodeForm from './LendiCodeForm';
import { useForm, FormProvider } from 'react-hook-form';
import Transition from '../../Shared/Transition';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

type LendiCodeEditDialogProps = {
  open: boolean;
  onClose: () => void;
  onSave: (data: LendiCode) => void;
  data: LendiCode;
};

const LendiCodeDialog: React.FC<LendiCodeEditDialogProps> = ({
  open,
  onClose,
  onSave,
  data,
}) => {
  const methods = useForm({
    defaultValues: {
      lendiCode: data.lendiCode,
      description: data.description,
    },
  });

  return (
    <Container>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        fullWidth
        maxWidth="lg">
        <DialogTitle>Edit Lendi Code</DialogTitle>
        <DialogContent>
          <FormProvider {...methods}>
            <LendiCodeForm />
          </FormProvider>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={methods.handleSubmit(onSave)}>
            Save
          </Button>
          <Button color="secondary" onClick={onClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default LendiCodeDialog;
