import React from 'react';
import { Tab, Tabs } from '@material-ui/core';
import { Rule } from '../../RuleDetail';

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

interface TabsProps {
  mitigationTabPanel: () => React.ReactNode;
  lendiCodePanel: () => React.ReactNode;
  forNewLender: boolean;
  rule: Rule;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`rule-details-tabpannel-${index}`}
      aria-labelledby={`rule-details-${index}`}
      {...other}>
      {children}
    </div>
  );
}

const a11yProps = (index: number) => ({
  id: `rule-details-tab-${index}`,
  'aria-controls': `rule-details-${index}`,
});

const RuleDetailsTabs: React.FC<TabsProps> = props => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const mitigationTabPanel = props.mitigationTabPanel();
  const lendiCodePanel = props.lendiCodePanel();

  return (
    <React.Fragment>
      <Tabs value={value} onChange={handleChange}>
        <Tab label="Mitigations" {...a11yProps(0)} />
        <Tab label="Lendi Code" {...a11yProps(1)} />
      </Tabs>

      <TabPanel value={value} index={0}>
        {mitigationTabPanel}
      </TabPanel>

      <TabPanel value={value} index={1}>
        {lendiCodePanel}
      </TabPanel>
    </React.Fragment>
  );
};

export default RuleDetailsTabs;
