import React, { useState, useEffect, useContext } from 'react';
import createAuth0Client from '@auth0/auth0-spa-js';
import Auth0Client from '@auth0/auth0-spa-js/dist/typings/Auth0Client';

interface Auth0Params {
  isAuthenticated: boolean;
  user: any;
  loading: boolean;
  auth0Client?: Auth0Client;
}

interface Auth0ProviderOptions {
  children: React.ReactNode;
  onRedirectCallback?: (state: any) => void;
  domain: string;
  audience: string;
  client_id: string;
  redirect_uri: string;
}

const defaultRedirectCallbackHandler = (_: any) =>
  window.history.replaceState({}, document.title, window.location.pathname);

export const Auth0Context = React.createContext<Auth0Params | null>(null);
export const useAuth0 = () => useContext(Auth0Context);
export const Auth0Provider: React.FC<Auth0ProviderOptions> = ({
  children,
  onRedirectCallback = defaultRedirectCallbackHandler,
  ...initOptions
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState<any>();
  const [auth0Client, setAuth0] = useState<Auth0Client>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const initAuth0 = async () => {
      const auth0FromHook = await createAuth0Client(initOptions);
      setAuth0(auth0FromHook);

      if (window.location.search.includes('code=')) {
        const { appState } = await auth0FromHook.handleRedirectCallback();
        onRedirectCallback(appState);
      }

      const isAuthenticated = await auth0FromHook.isAuthenticated();
      setIsAuthenticated(isAuthenticated);

      if (isAuthenticated) {
        const user = await auth0FromHook.getUser();
        setUser(user);
        const accessToken = await auth0FromHook.getTokenSilently();
        sessionStorage.setItem('token', accessToken);
      }

      setLoading(false);
    };
    initAuth0();
    // eslint-disable-next-line
  }, []);

  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        user,
        loading,
        auth0Client,
      }}>
      {children}
    </Auth0Context.Provider>
  );
};
