import React from 'react';
import './client/axios';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import {
  withThemeProvider,
  withApolloProvider,
  withAuth0Provider,
  withErrorDialogProvider,
  withLenderDataProvider,
  withDocTypeProvider,
} from './providers';
import { withAuth } from './services/auth.service';
import AuthScreen from './components/AuthScreen';
import UnauthorizedScreen from './components/UnauthorizedScreen';
import DefaultLayout from './components/DefaultLayout';
import NewRuleScreen from './components/NewRuleScreen';
import AllRulesScreen from './components/RulesScreen/AllRules';
import VariationRulesScreen from './components/RulesScreen/VariationRules';
import NewLendingRules from './components/RulesScreen/NewLendingRules';
import LendiCodeManagementScreen from './components/LendiCodeScreen/LendiCodeManagement';
import IacLenderScreen from './components/IacLendersScreen';
import { loadDevMessages, loadErrorMessages } from '@apollo/client/dev';

if (process.env.REACT_APP_ENV === 'development') {
  loadDevMessages();
  loadErrorMessages();
}

const App: React.FC = () => (
  <BrowserRouter basename={process.env.REACT_APP_ROUTE_PATH}>
    <Switch>
      <Route exact path="/sign-in" component={AuthScreen} />
      <Route exact path="/unauthorized" component={UnauthorizedScreen} />
      <DefaultLayout>
        <Route exact path="/" component={withAuth(AllRulesScreen)} />
        <Route
          exact
          path="/new-lending-rules"
          component={withAuth(NewLendingRules)}
        />
        <Route
          exact
          path="/variation-rules"
          component={withAuth(VariationRulesScreen)}
        />
        <Route exact path="/new-rule" component={withAuth(NewRuleScreen)} />
        <Route
          exact
          path="/lendi-code-management"
          component={withAuth(LendiCodeManagementScreen)}
        />
        <Route
          exact
          path="/iac-lenders"
          component={withAuth(IacLenderScreen)}
        />
      </DefaultLayout>
    </Switch>
  </BrowserRouter>
);

export default withThemeProvider(
  withErrorDialogProvider(
    withAuth0Provider(
      withApolloProvider(withLenderDataProvider(withDocTypeProvider(App)))
    )
  )
);
