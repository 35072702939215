// @ts-nocheck
import { configure } from 'axios-hooks';
import axios, { AxiosRequestConfig } from 'axios';

export const theAxios = axios.create({
  headers: {
    'Content-Type': 'application/vnd.api+json',
  },
});

const getToken = () => {
  return new Promise((resolve, reject) => {
    const interval = setInterval(() => {
      const token = sessionStorage.getItem('token');
      if (token) {
        clearInterval(interval);
        return resolve(token);
      }
    }, 50);
  });
};

async function setAuthHeader(config: AxiosRequestConfig) {
  // @ts-ignore
  config.headers['Authorization'] = 'Bearer ' + (await getToken());
  return config;
}

function unauthenticated(error: any) {
  if (error.response.status === 401) {
    sessionStorage.removeItem('token');
    window.location.href = '/sign-in';
  }
  return error;
}

theAxios.interceptors.request.use(async config => setAuthHeader(config));

axios.interceptors.request.use(async config => setAuthHeader(config));

theAxios.interceptors.response.use(
  response => response,
  error => unauthenticated(error)
);

axios.interceptors.response.use(
  response => response,
  error => unauthenticated(error)
);

configure({ axios: theAxios });
