import React, { useState, useEffect, useRef } from 'react';
import LenderMultiSelect from '../LenderMultiSelect';
import styled from 'styled-components';
import {
  useGetIacLendersConfigQuery,
  useCreateIacLendersConfigMutation,
} from '../../graphql/types';
import { useErrorDialog } from '../../hooks/error-dialog';
import Loading from '../Loading';
import { Button, Snackbar } from '@material-ui/core';
import ImportExportToolBar from '../Shared/ImportExportToolBar';
import * as FileSaver from 'file-saver';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { createStyles } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  width: 100%;
`;

const useStyles = makeStyles(_ =>
  createStyles({
    fileInput: {
      display: 'none',
    },
  })
);

const IacLenderScreen: React.FC = () => {
  const classes = useStyles();

  const configuredLenders = useGetIacLendersConfigQuery();
  const {
    refetch,
    loading: getLendersQueryLoading,
    error: getLendersQueryError,
  } = configuredLenders;
  const [
    updateLenders,
    {
      loading: createLendersMutationLoading,
      error: createLendersMutationError,
    },
  ] = useCreateIacLendersConfigMutation();
  const [selectedLender, setSelectedLender] = useState(['']);

  const [isLoading, setLoading] = useState(false);
  const [isError, setError] = useState(false);
  const [
    iacLendersOperationInProgress,
    setIacLendersOperationInProgress,
  ] = useState<boolean>(false);
  const errorDialog = useErrorDialog();

  useEffect(() => {
    const data = configuredLenders.data?.getIacLendersConfig?.lenders || [];
    setSelectedLender(data);
  }, [configuredLenders]);

  useEffect(() => {
    setLoading(getLendersQueryLoading || createLendersMutationLoading);
  }, [getLendersQueryLoading, createLendersMutationLoading]);

  useEffect(() => {
    setError(
      getLendersQueryError !== undefined ||
        createLendersMutationError !== undefined
    );
  }, [getLendersQueryError, createLendersMutationError]);

  const inputEl = useRef<HTMLInputElement | null>(null);

  const submitIacLendersConfig = () => {
    updateLenders({
      variables: {
        lenderIds: selectedLender,
      },
    }).then(_ => refetch());
  };

  if (isError) {
    errorDialog?.showErrorDialog(true);
    setError(false);
  }

  if (isLoading) {
    return <Loading />;
  }

  const handleExportIacLenders = () => {
    const blob = new Blob([JSON.stringify(selectedLender, null, 1)], {
      type: 'application/json;charset=utf-8',
    });
    FileSaver.saveAs(
      blob,
      `${
        process.env.REACT_APP_ENV
      }-iac-lenders-${new Date().toISOString()}.json`
    );
  };

  const handleImportIacLenders = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setIacLendersOperationInProgress(true);
    const file = event.target.files?.item(0);
    if (file) {
      const fileReader = new FileReader();
      fileReader.onload = function(e) {
        const raw = fileReader.result;
        const json = JSON.parse(raw?.toString() || '[]');
        const iacLendersToImport = json as string[];
        updateLenders({
          variables: {
            lenderIds: iacLendersToImport,
          },
        })
          .then(_ =>
            refetch().catch(_ => {
              errorDialog?.showErrorDialog(true);
            })
          )
          .catch(_ => {
            errorDialog?.showErrorDialog(true);
          })
          .finally(() => setIacLendersOperationInProgress(false));
      };
      fileReader.readAsText(file, 'application/json;charset=utf-8');
    }
  };

  const handleImportLendiCodeClicked = () => {
    inputEl.current!.click();
  };

  const FileInput = () => (
    <input
      type="file"
      className={classes.fileInput}
      ref={inputEl}
      onChange={e => handleImportIacLenders(e)}
    />
  );

  return (
    <Container>
      <h2>Configured IAC Lenders</h2>
      <ImportExportToolBar
        selectedRows={selectedLender}
        handleExportToJson={handleExportIacLenders}
        handleExportToCsv={() => {}}
        handleImportClicked={handleImportLendiCodeClicked}
        tableName=""
        shouldDisplayExportButtons={selectedLender.length > 0}
        shouldDisplayImportButtons={true}
        shouldHighlightToolBar={false}
        shouldDisplayNumberOfItemsSelected={false}
      />
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={iacLendersOperationInProgress}
        autoHideDuration={500}>
        <Alert severity="info">IAC lender operation in progress!</Alert>
      </Snackbar>
      <LenderMultiSelect
        label="Update IAC Lenders"
        selectedLenderUUIDs={selectedLender}
        onSelectedLenders={lenders => {
          setSelectedLender(lenders);
        }}
      />
      <ButtonContainer>
        <Button onClick={() => submitIacLendersConfig()}>Save</Button>
      </ButtonContainer>
      <FileInput />
    </Container>
  );
};

export default IacLenderScreen;
